import { TempleImages } from 'assets/images/Temples/TempleImages';
import Gallery from 'components/Package/Gallery'
import { useStateProvider } from 'context/StateContext'
import React from 'react'
const TempleDetails = () => {
    const [{currTemple}, dispatch] = useStateProvider();
  return (
    <div className='mx-5 px-3 mb-5'>
        <div className='text-center my-6'>
            <h1 className='text-3xl text-orange-500 font-bold'>
                {currTemple?.name}
            </h1>
        </div>
        <div className='justify-center flex mx-[10%]'>
            <Gallery bool={true} images={TempleImages[currTemple?.id]} about={"Photos"}/>
        </div>
        <div className='border border-orange-500 rounded-md p-5 my-5'>
            <div className='mb-3'>
                <h2 className='text-xl font-semibold text-gray-700'>Description</h2>
                <p className='ml-2 text-gray-600'>{currTemple?.long_desc}</p>
            </div>
            <div className='mb-5'>

                <h2 className='text-xl font-semibold text-gray-700'>Darshan Timing</h2>
                <p className='ml-2 text-gray-600'>{currTemple?.darshan_timing}</p>
            </div>
            {
                currTemple?.aarti_timings.length !== 0 && <div>
                    <h2 className='text-xl font-semibold text-gray-600'>Aarti Timing</h2>
                    {
                        currTemple?.aarti_timings.map((_)=>{
                            return(
                                <div>
                                    <p className='ml-2 text-gray-600'>
                                        {_}
                                    </p>
                                </div>
                            )
                        })
                    }
                </div>
            }
        </div>
    </div>
  )
}

export default TempleDetails