import kaalSarp from './KaalSarp.jpeg';
import grahShanti from './grahShanti.jpeg'
import chandra from './chandraShanti.jpeg'
import mabagla from './maa_baglamukji_jaap_havan.jpeg'
import mjaap from './mahamrityumJaap.jpeg'
import rketu from './rahuKetuPooja.jpeg'
import bhatPuja from './मंगल-भात-पूजन.jpeg'
import pitraDosh from './pitraDosh.webp'
import rudraAbhishek from './rudraAbhishek.webp'
import laghuRudra from './laghuRudraAbhishek.webp'
import mjaap1 from './mJaap1.jpg'
import rnMukti from './rinMukti.jpeg'
const ImageObj= {
    11:mjaap,
    10:grahShanti,
    9:rketu,
    8:mabagla,
    7:chandra,
    1:kaalSarp,
    0:bhatPuja,
    2:pitraDosh,
    3:rudraAbhishek,
    4:laghuRudra,
    5:mjaap1,
    6:rnMukti
}
export default ImageObj
