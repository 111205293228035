export const reducerCases = {
  SET_MODAL: "SET_MODAL",
  SET_POOJA_BOOKING_SUMMARY : "SET_POOJA_BOOKING_SUMMARY",
  CURRENT_POOJA : "CURRENT_POOJA",
  SET_USER : "SET_USER",
  SET_USER_OTP : "SET_USER_OTP",
  SET_AUTH_TOKENS : "SET_AUTH_TOKENS",
  SET_PREV_PAGE : "SET_PREV_PAGE",
  SET_PREMIUM_PACKAGE:"SET_PREMIUM_PACKAGE",
  SET_VEHICLE:"SET_VEHICLE",
  SET_PACKAGE_PRICE:"SET_PACKAGE_PRICE",
  SET_PACKAGE_DATE:"SET_PACKAGE_DATE",
  SET_ECONOMY_PACKAGE:"SET_ECONOMY_PACKAGE",
  SET_CURRENT_ORDER:"SET_CURRENT_ORDER",
  SET_CURRENT_TEMPLE:"SET_CURRENT_TEMPLE",
  SET_PICKUP:"SET_PICKUP"
};

export const packages = {
  PREMIUM_PACKAGE : "premiumPackage",
  ECONOMY_PACKAGE : "economyPackage"
}
