import React, { useState } from "react";
import { FaBuilding, FaWhatsapp } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";
import { SEND_QUERY_MAIL } from "utils/ApiRoutes";
import axios from "axios";
import Loader from "./Loader";

const ContactUs = () => {
  const [queryData, setqueryData] = useState({
    firstName:"",
    lastName:"",
    senderEmail:"",
    phoneNumber:"",
    emailBody:""
  });
  const [loading, setloading] = useState(false);
  const handleFormClick = async (e)=>{
    setloading(true)
    e.preventDefault()
    try {
      const {data} = await axios.post(SEND_QUERY_MAIL, queryData);
      setloading(false)
      if(data.status){
        alert("An Email has been sent to us. Our team will contact you shortly");
      }
    } catch (error) {
      console.log(error);
    }
  }



  return (
    <div className="bg-gradient-to-br from-gray-100 via-white to-gray-100 px-6 py-12 md:px-16">
      <div className="text-center mb-10">
        <h1 className="font-extrabold text-4xl md:text-5xl text-gray-800">Contact Us</h1>
        <p className="text-gray-600 text-lg md:text-xl mt-4">
          We use an agile approach to test assumptions and connect with the
          needs of your audience early and often.
        </p>
      </div>
      <div className="grid md:grid-cols-3 gap-10">
        <form
          id="contact-form"
          className="md:col-span-2 bg-white p-8 rounded-lg shadow-lg"
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="mb-6">
              <label
                htmlFor="firstName"
                className="block text-sm font-semibold text-gray-700"
              >
                First Name
              </label>
              <input
                onChange={e=>setqueryData({
                  ...queryData,
                  firstName:e.target.value
                })}
                value={queryData.firstName}
                type="text"
                id="firstName"
                className="w-full px-4 py-2 mt-2 text-gray-900 border rounded-lg focus:ring-2 focus:ring-blue-400 focus:outline-none"
                placeholder="First Namme"
                required
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="lastName"
                className="block text-sm font-semibold text-gray-700"
              >
                Last Name
              </label>
              <input
                onChange={e=>setqueryData({
                  ...queryData,
                  lastName:e.target.value
                })}
                value={queryData.lastName}
                type="text"
                id="lastName"
                className="w-full px-4 py-2 mt-2 text-gray-900 border rounded-lg focus:ring-2 focus:ring-blue-400 focus:outline-none"
                placeholder="Last Name"
                required
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="email"
                className="block text-sm font-semibold text-gray-700"
              >
                Your email
              </label>
              <input
                onChange={e=>setqueryData({
                  ...queryData,
                  senderEmail:e.target.value
                })}
                value={queryData.senderEmail}
                type="email"
                id="email"
                className="w-full px-4 py-2 mt-2 text-gray-900 border rounded-lg focus:ring-2 focus:ring-blue-400 focus:outline-none"
                placeholder="name@example.com"
                required
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="phoneNumber"
                className="block text-sm font-semibold text-gray-700"
              >
                Phone Number
              </label>
              <input
                onChange={e=>setqueryData({
                  ...queryData,
                  phoneNumber:e.target.value
                })}
                value={queryData.phoneNumber}
                type="number"
                id="phoneNumber"
                placeholder="+91 XXXXXXXXXX"
                className="w-full px-4 py-2 mt-2 text-gray-900 border rounded-lg focus:ring-2 focus:ring-blue-400 focus:outline-none"
                required
              />
            </div>
            <div className="md:col-span-2 mb-6">
              <label
                htmlFor="message"
                className="block text-sm font-semibold text-gray-700"
              >
                Your message
              </label>
              <textarea
                required
                onChange={e=>setqueryData({
                  ...queryData,
                  emailBody:e.target.value
                })}
                value={queryData.emailBody}
                id="message"
                rows="4"
                className="w-full px-4 py-2 mt-2 text-gray-900 border rounded-lg focus:ring-2 focus:ring-blue-400 focus:outline-none"
                placeholder="Leave a comment..."
              ></textarea>
            </div>
          </div>
          <p className="text-sm text-gray-600 mt-4 mb-6">
            By submitting this form, you agree to our terms and privacy policy.
          </p>
          <button
            disabled={loading}
            onClick={e=>handleFormClick(e)}
            type="submit"
            className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2.5 rounded-lg shadow-md focus:ring-4 focus:ring-blue-300 transition-all duration-200"
          >
            {loading ? (
              <Loader size={21} color={"white"} speed={2.0} stroke={3} bgOpacity={""}/>
            ) : (
              
              "Send Message"
            )}
          </button>
        </form>
        <div className="text-center flex flex-col justify-around bg-white p-8 rounded-lg shadow-lg">
          <div className="mb-8">
            <FaBuilding className="text-6xl text-blue-500 mx-auto" />
            <h3 className="text-xl font-bold mt-4">Company Information</h3>
            <p className="text-gray-600">Avadhoot Journey Pvt. Ltd.</p>
            <p className="text-gray-600">Tax ID: XXXXXFA</p>
          </div>

          <div className="mb-8">
            <FaLocationDot className="text-6xl text-red-500 mx-auto" />
            <h3 className="text-xl font-bold mt-4">Our Address</h3>
            <p className="text-gray-600">
              162, Tirupati Platinum, Daukhedi, Ujjain 456006 (M.P)
            </p>
          </div>

          <div className="mb-8">
            <FaPhoneAlt className="text-6xl text-green-500 mx-auto" />
            <h3 className="text-xl font-bold mt-4">Call Us</h3>
            <p className="text-gray-600">+91 9201968470</p>
            <p className="text-gray-600">We’re happy to help!</p>
          </div>
        </div>

        <div className="md:col-span-3 mt-10">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3668.492672805149!2d75.76565757518384!3d23.15221211147823!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39637539d6904c5f%3A0x44525cf5e06e0e84!2sTirupati%20Platinum!5e0!3m2!1sen!2sin!4v1726141885306!5m2!1sen!2sin"
            width="100%"
            height="430"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
