import * as React from "react";
import DetailModal from "./DetailModal";
import { useState } from "react";
import { useStateProvider } from "context/StateContext";
import { reducerCases } from "context/constants";
import { useNavigate } from "react-router-dom";
import { getPoojaMap } from "Poojas";
import { useLocalStorage } from "CustomHooks/LocalStorage";
import { AspectRatio } from "@radix-ui/react-aspect-ratio";
import { trackButtonClicks } from "Analytics/GoogleAnalytics";

export default function ContentCard({
  ind,
  src,
  title,
  short_desc,
  long_desc,
  price,
  place
}) {
  const [{ modal }, dispatch] = useStateProvider();
  const [poojaMap, setpoojaMap] = useState(getPoojaMap());
  const [setLocalStorage] = useLocalStorage("currentPooja");
  const navigate = useNavigate();
  const handleBookPooja = () => {
    const pooja = poojaMap.get(ind);
    dispatch({
      type: reducerCases.CURRENT_POOJA,
      currentPooja: pooja,
    });
    localStorage.setItem("currentPooja", JSON.stringify(pooja));
    let t = (title = title.replaceAll(" ", "-"));
    trackButtonClicks(
      "book_pooja_btn",
      "custom_event",
      `To book ${t}`
    );
    navigate(`/pooja/${t}`);
  };
  const handleCardClick = (e) => {
    if (e.target.nodeName === "BUTTON") {
      return;
    }
    // const t = e.currentTarget.firstElementChild.childNodes;
    // const src = t[0].children[0].attributes.src.value;
    // const title = t[1].children[0].innerText;
    // const desc = long_desc;

    dispatch({
      type: reducerCases.SET_MODAL,
      modal: {
        bool: true,
        src,
        title,
        desc:long_desc,
        ind,
      },
    });
  };
  return (
    <>
      {modal && <DetailModal />}
      <div
        onClick={(e) => handleCardClick(e)}
        className="pr-0 pl-0 w-72 my-8 px-3 shadow-xl hover:translate-y-[-5px] transition-transform rounded-lg md:w-[70%] cursor-pointer"
      >
        <div className="h-full px-2 flex flex-col justify-between">
          <div className="relative overflow-hidden w-[100%] h-full">
            <img className="rounded-lg w-[100%] h-[100%] object-cover" src={src} />
          </div>
          <div className="relative">
            <div className="min-h-[100] mt-1">
              <div>
                <h3 className="text-xl font-semibold overflow-hidden ">
                  {title}
                </h3>
              </div>
              <div>
                <h5 className="text-gray-500 overflow-hidden text-ellipsis whitespace-nowrap">
                  {short_desc}
                </h5>
              </div>
              <div>
                {
                  place.length!==0 && <div className="my-2 text-gray-500 text-sm inline-block bg-orange-100 px-1 rounded-sm text-">Performed at {place}</div>
                }
                <h5 className="text-gray-500 text-lg overflow-hidden text-ellipsis whitespace-nowrap">
                  {price}
                </h5>
              </div>
            </div>
            <div className="mt-2 py-3">
              <button onClick={handleBookPooja} className="book_btn mb-0">
                Book Pooja
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
