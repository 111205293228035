import React from 'react';
import { NavLink } from 'react-router-dom';

const ComingSoon = () => {
  return (
    <div className="md:h-full h-[84vh]   p-4  flex items-center justify-center">
      <div className="bg-white   shadow-xl rounded-lg py-2  max-w-3xl mx-auto text-center">
        <h1 className="text-5xl font-extrabold text-gray-800 mb-4">Coming Soon!</h1>
        <p className="text-lg text-gray-600 mb-2">
          We're working on something amazing! Our Android app is under development and will be available soon. Stay tuned!
        </p>
        <div className="flex justify-center gap-4 mb-4">
          <img 
            src="./mobileView.png" 
            alt="App Demo " 
            className="rounded-2xl shadow-md md:w-40 md:h-72 h-40 w-20"
          />
          <img 
            src="./mobileView2.png" 
            alt="App Demo " 
            className="rounded-xl shadow-md md:w-40 md:h-72 h-40 w-20"
          />
        </div>
     
       <div className=' flex justify-evenly items-center'>
       <button className="bg-gradient-to-l md:w-[270px] w-[170px] from-orange-400 to-orange-600 text-white font-bold py-3 md:px-6 px-3 md:text-[16px] text-[12px] rounded-full transition duration-300 ease-in-out">
          Coming Soon on Android
        </button>
        <a href={"/"}  className="md:w-[270px] w-[170px] bg-gradient-to-r from-orange-400 to-orange-600 text-white font-bold py-3 md:px-6 px-3 md:text-[16px] text-[12px] rounded-full transition duration-300 ease-in-out">
          Go to Home
        </a>
        </div> 
      </div>
    </div>
  );
};

export default ComingSoon;