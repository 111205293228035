import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { FaExternalLinkAlt } from "react-icons/fa";
import { GET_ALL_ORDERS } from "utils/ApiRoutes";
import axios from "axios";
const MyOrders = () => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [invoice, setInvoice] = useState(true);
  const [orders, setOrders] = useState([]);
  const [orderForDetail, setOrderForDetail] = useState({})

  useEffect(()=>{
    const getAllOrders = async()=>{
      try {
        const {data} = await axios.get(GET_ALL_ORDERS,{
          params:{
            userId:user.id
          }
        });
        console.log(data);
        
        if(data.status){
          setOrders(data.orders)
        }
      } catch (error) {
        console.log(error);
      }
    }
    getAllOrders();
  },[])

  const handleOrderRowClick = (id)=>{
    setInvoice(false);
    const o = orders.find((order)=>{
      return order.id === id
    })
    setOrderForDetail(o);
  }
  return (
    <div className="md:px-10 mx-5">
      {invoice ? (
        <div className="mt-20">
          <h3 className="text-3xl py-3 px-3 font-semibold">My Orders</h3>
          <div>
            <div className="px-3 mb-5">
              <Button variant="text">Orders</Button>
              <Button variant="text">Cancelled Orders</Button>
              <hr className="border" />
            </div>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-white uppercase bg-black dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Order
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Type
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Persons
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Price
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {orders.length === 0 ? (
                    <tr>
                      <th className="px-6 py-4">
                      No Orders placed yet
                      </th>
                    </tr>
                  ) : (
                    orders.map((order)=>{
                      return (

                        <tr key={order.id} onClick={()=>handleOrderRowClick(order.id)} className="bg-white border-b cursor-pointer dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                      
                        <th
                          scope="row"
                          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          {order.title}
                        </th>
                        <td className="px-6 py-4">{order.category}</td>
                        <td className="px-6 py-4">{order.persons}</td>
                        <td className="px-6 py-4">{order.total}</td>
                        <td className="px-6 py-4">{order.createdAt.split("T")[0]}</td>
                      </tr>
                      )
                    })
                      
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <div className="border rounded-sm p-5 mt-20">
            <div className="py-4">
                <h1 className="text-2xl underline text-center font-semibold">
                    Details
                </h1>
            </div>
          <div className="text-center">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-5 justify-items-start text-start text-xl bg-slate-100 px-3 py-3 my-3">
              <div>
                <h3 className="font-semibold">Order No:{orderForDetail.orderNumber}</h3>
                <h3 className="font-semibold">Order Date:{orderForDetail.createdAt.split("T")[0]}</h3>
              </div>
              <div>
                <h3 className="">Order Item(s),</h3>
                <div className="font-semibold">
                  <h3>{orderForDetail.title}</h3>{orderForDetail.persons} Person
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-y-10 md:grid-cols-3 text-start px-3">
              <div>
                <h3 className="font-semibold">Recipient Details</h3>
                <div className="text-sm ">
                  <p>{user.firstName}</p>
                  <p>{user.email}</p>
                  <p>+{user.phoneNumber}</p>
                </div>
              </div>
              <div>
                <h3 className="font-semibold">Payment Details</h3>
                <div className="text-sm">
                  <h4>
                    STATUS :{" "}
                    <span className="bg-yellow-100 font-semibold text-green-400 px-1">
                      PAID
                    </span>
                  </h4>
                  <p>Transaction ID : {orderForDetail.payment.transactionId}</p>
                  <p>Payment Method : {orderForDetail.payment.method}</p>
                </div>
              </div>
              <div>
                <h3 className="font-semibold">Order Summary</h3>
                <div className="grid grid-cols-2 text-sm">
                  <h4>Items(s) Subtotal</h4>
                  <p> {orderForDetail.subTotal} </p>
                  <h4 className="font-semibold mt-2">Grand Total</h4>
                  <h4 className="font-semibold mt-2">{orderForDetail.total}</h4>
                </div>
              </div>
            </div>
            <hr className="border my-3" />

            <div className="my-3">
              <p className="text-red-500">
                Note : Please refer to our{" "}
                <span className="text-purple-600">
                  <a
                    href="/refund_policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaExternalLinkAlt className="inline-block text-sm" />{" "}
                    Refund Policy
                  </a>
                </span>{" "}
                before cancelling the order.
              </p>
            </div>
            <Button
              sx={{ marginRight: "10px" }}
              variant="contained"
              color="primary"
              size="small"
              onClick={()=>setInvoice(true)}
            >
              <IoMdArrowRoundBack className="mr-2" />{" "}
              <span className="text-sm">Go Back</span>
            </Button>
            <Button variant="contained" color="error" size="small">
              <span className="text-sm">Cancel Order</span>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyOrders;
