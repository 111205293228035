import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { TempleImages } from 'assets/images/Temples/TempleImages';
import { useNavigate } from 'react-router-dom';
import { useStateProvider } from 'context/StateContext';
import { reducerCases } from 'context/constants';

const TempleCard = ({id ,name, short_desc, long_desc, darshan_timing, aarti_timings, poojas}) => {
    const navigate = useNavigate();
    const [{}, dispatch] =  useStateProvider()
    const hadnleTempleClick = ()=>{
        dispatch({
            type:reducerCases.SET_CURRENT_TEMPLE,
            currTemple:{
                id,
                name,
                long_desc,
                darshan_timing,
                aarti_timings,
                poojas
            }
        });
        let param = name.replaceAll(" ","-")
        navigate(`/temples/${param}`)
    }
    return (
        <Card sx={{ maxWidth: 345 }}>
          <CardMedia
            component="img"
            alt={name}
            height="140"
            image={TempleImages[id][0]}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {name}
            </Typography>
            <Typography sx={{textWrap:"nowrap", textOverflow:"ellipsis", overflow:"hidden"}} variant="body2" color="text.secondary">
              {long_desc}
            </Typography>
          </CardContent>
          <CardActions>
            <Button onClick={hadnleTempleClick} size="small">Details</Button>
          </CardActions>
        </Card>
    );
}

export default TempleCard