import React, { useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { RiSubtractFill } from "react-icons/ri";
const ControlButton = ({yajmans, setYajmans}) => {
  // const [yajmans, setYajmans] = useState("0");
  const handleInc = (e) => {
    if(yajmans === ""){
      setYajmans(1)
    }else{

      setYajmans(parseInt(yajmans) + 1);
    }
  };
  const handleDEc = () => {
    if (yajmans > 1) {
      setYajmans(yajmans - 1);
    }
  };
  const handleChange = (e) => {
    if (e.target.value !== "") {
      setYajmans(0 + parseInt(e.target.value));
    } else {
      setYajmans("");
    }
  };
  return (
    <>
      <label
        htmlFor="quantity-input"
        className="mb-2 text-lg mx-5 pt-2 text-gray-900 dark:text-gray-800"
      >
        Add Yajmans :
      </label>
      <div className="relative flex items-center max-w-[8rem]">
        <button
          onClick={e=>handleDEc(e)}
          type="button"
          className="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-s-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:outline-none"
        >
          <RiSubtractFill />
        </button>
        <input
          onChange={(e) => handleChange(e)}
          value={yajmans}
          type="text"
          id="quantity-input"
          data-input-counter
          aria-describedby="helper-text-explanation"
          className="bg-gray-50 border border-x-0 border-gray-300 h-11 text-center text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="1"
          required
        />
        <button
          onClick={e=>handleInc(e)}
          type="button"
          className="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-e-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:outline-none"
        >
        
          <IoMdAdd />
        </button>
      </div>
    </>
  );
};

export default ControlButton;
