import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { StateProvider } from "./context/StateContext";
import reducer, { initialState } from "./context/StateReducers";
import { AppRouter } from './router/AppRouter';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AppRouter>
      <StateProvider reducer={reducer} initialState={initialState} >
        <App/>
      </StateProvider>
    </AppRouter>
);
