import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineUser } from "react-icons/ai";
import { HiMiniBars3 } from "react-icons/hi2";
import Cookies from "universal-cookie";

import avadhootImage from "assets/images/zwhite-logo.png"; // Replace with the correct path
import logoImage from "assets/images/logo1.png"; // Replace with the correct path
import { trackButtonClicks } from "Analytics/GoogleAnalytics";

//
// packages
// pooja

const Navbar = () => {
  const [navbarHidden, setNavbarHidden] = useState(true);
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const navigate = useNavigate();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const toggleNavBar = (e) => {
    setNavbarHidden(!navbarHidden);
  };
  const handleSignOut = () => {
    const cookeis = new Cookies();
    cookeis.remove("accessToken");
    sessionStorage.removeItem("user");
    navigate("/login");
  };
  return (
    <nav className="bg-white border-gray-200 bg-gradient-to-r from-amber-500 via-orange-600 to-yellow-500 dark:border-black sticky top-0 shadow-md z-20">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-3">
        <a href="/" className="flex items-center rtl:space-x-reverse">
          {/* Increased size of Logo and Avadhoot Image */}
          <img src={logoImage} className="md:h-12 h-8 w-auto" alt="Logo" />
          <img
            src={avadhootImage}
            className="md:h-12 h-8 mb-3 w-auto "
            alt="Avadhoot"
          />
        </a>
        <button
          data-collapse-toggle="navbar-multi-level"
          type="button"
          className="inline-flex items-center md:hidden p-2 w-10 h-10 justify-center text-sm text-white rounded-lg  hover:bg-yellow focus:outline-none shadow-md"
          aria-controls="navbar-multi-level"
          aria-expanded="false"
          onClick={(e) => toggleNavBar(e)}
        >
          <HiMiniBars3 className="text-4xl font-bold" />
        </button>
        <div
          className={`${
            navbarHidden && "hidden"
          } duration-300 w-full md:block md:w-auto`}
          id="navbar-multi-level"
        >
          <ul className="flex flex-col font-medium p-2 md:p-0 mt-1 rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:shadow-none">
            <li>
              <a href="/" className="list_items">
                Home
              </a>
            </li>
            <li>
              <a onClick={()=>trackButtonClicks("Poojas_Navlink","custom_event", "To browse Poojas")} href="/browse-poojas" className="list_items">
                Pooja/Havans
              </a>
            </li>
            <li>
              <a onClick={()=>trackButtonClicks("Packages_Navlink","custom_event", "To browse packages")} href="/browse-packages" className="list_items">
                Packages
              </a>
            </li>
            <li>
              <a onClick={()=>trackButtonClicks("about_us","custom_event", "about us")} href="/about-us" className="list_items">
                About us
              </a>
            </li>
            <li>
              <a onClick={()=>trackButtonClicks("contact_us_button","custom_event", "contact us")} href="/#contact-form" className="list_items">
                Contact us
              </a>
            </li>
            <li className="list_items">
              <div
                onClick={() => setToggleDropdown(!toggleDropdown)}
                className="flex relative cursor-pointer"
              >
                <div className="flex">
                  <AiOutlineUser className="text-2xl text-white" />
                  {toggleDropdown && (
                    <div className="dropdown">
                      {user && (
                        <>
                          <h1 className="dropdown_link">
                            Welcome, {user.firstName}
                          </h1>
                          <a
                            href="/my-orders"
                            className="dropdown_link"
                            onClick={() => setToggleDropdown(false)}
                          >
                            My Orders
                          </a>
                        </>
                      )}

                      {user ? (
                        <button
                          type="button"
                          onClick={() => {
                            setToggleDropdown(false);
                            handleSignOut();
                          }}
                          className="mt-5 w-full black_btn"
                        >
                          Sign Out
                        </button>
                      ) : (
                        <>
                          <button
                            type="button"
                            onClick={() => {
                              setToggleDropdown(false);
                              navigate("/login");
                              trackButtonClicks("Login Button", "For Login")
                            }}
                            className="mt-5 w-full black_btn"
                          >
                            Sign in
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              setToggleDropdown(false);
                              navigate("/register");
                              trackButtonClicks("Register Button", "For Register")
                            }}
                            className=" w-full black_btn"
                          >
                            Register
                          </button>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
