import axios from "axios";
import Loader from "components/Loader";
import { useStateProvider } from "context/StateContext";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { PHONEPE_ROUTE, RAZORPAY_ORDER } from "utils/ApiRoutes";
import { LoadRazorPayScripts } from "utils/RazorPayScript";
import { v4 as uuidv4 } from "uuid";

const OrderSummary = () => {
  const [isLoading, setisLoading] = useState(false);
  const [{ order }, dispatch] = useStateProvider();
  const user = JSON.parse(sessionStorage.getItem("user"));
  const currOrder = JSON.parse(sessionStorage.getItem("currOrder"));
  const [orderState, setorderState] = useState(
    order.title === "" ? currOrder : order
  );

  const calculateTotal = () => {
    let total = orderState.subTotal;
    orderState?.addOns?.forEach(item=>total+=item.price)
    return total;
  };

  const handlePayment = async () => {
    setisLoading(true);
    let amount = calculateTotal() * 100;
    let paymentOptions = {
      id: user.id,
      amount,
      currency: "INR",
    };
    try {
      const currentTransactionId = uuidv4();
      sessionStorage.setItem(
        "currOrder",
        JSON.stringify({
          ...currOrder,
          currentTransactionId,
          total: calculateTotal(),
        })
      );
      const response = await axios.post(PHONEPE_ROUTE, {
        number: user?.phoneNumber.slice(2, user.phoneNumber.length),
        amount,
        id: currentTransactionId,
      });
      window.location.href = response.data.url;
      //---------------- RazorPay gateway ---------------------

      // const {data} = await axios.post(RAZORPAY_ORDER, {paymentOptions});
      // const res = await LoadRazorPayScripts(
      //   "https://checkout.razorpay.com/v1/checkout.js"
      // );
      // const options = {
      //   key: "rzp_test_DjZ53r29Ku6q2R",
      //   amount,
      //   currency: "INR",
      //   name: "Avadhoot pvt. Ltd.",
      //   description: `Payment for ${order.title} `,
      //   handler: function (response) {
      //     console.log(response);
      //     alert(response.razorpay_payment_id);
      //     alert(response.razorpay_order_id);
      //     alert(response.razorpay_signature);
      //   },
      //   prefill: {
      //     name: "Avadhoot pvt. Ltd.",
      //     email: "avadhootapp1997@gmail.com",
      //   },
      //   theme: {
      //     color: "#3399cc",
      //   },
      // };
      // const paymentObject = new window.Razorpay(options)
      // paymentObject.open()
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="mb-10 px-5 flex flex-wrap gap-4 justify-around items-center">
      <div className="py-5  px-4 md:min-w-[40vw] w-[100%] max-w-[1200px] mt-6 h-full border border-gray-400 rounded-lg">
        <h1 className="text-3xl font-bold mb-5">Billing Details</h1>
        <div className="border px-4  py-10 rounded-lg">
          <h1 className="font-bold text-2xl mx-6 pb-2">Bill to :</h1>
          <div className="bg-slate-100 px-4 py-4 w-full">
            <h1 className="font-semibold text-lg">
              {user.firstName} {user.lastName}
            </h1>
            <div className="mt-2 leading-[15px]">
              <h3 className="text-gray-400">Phone Number</h3>
              <h1 className="font-semibold text-lg">+{user.phoneNumber}</h1>
            </div>
            <div className="mt-2 leading-[15px]">
              <h3 className="text-gray-400">Email Address</h3>
              <h1 className="font-semibold text-lg">{user.email}</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4 md:min-w-[50vw] w-[100%] max-w-[1200px]">
        <div className="border px-5 border-gray-400 shadow-xl relative overflow-x-auto rounded-lg py-6">
          <h1 className="text-3xl font-bold mb-5">Order Summary</h1>
          <div className="pb-10 pt-3">
            <h1 className="text-xl font-semibold">Order No. : {uuidv4()}</h1>
          </div>
          <table className="w-[100%] text-sm text-center text-gray-500 dark:text-gray-400">
            <thead className="md:text-xs text-[10px] text-gray-700 uppercase bg-gray-300 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-2 py-3">
                  Order
                </th>
                <th scope="col" className="px-2 py-3">
                  Category
                </th>
                <th scope="col" className="px-2 py-3">
                  {order.category === "Pooja" || "ePooja"
                    ? "Yajmans"
                    : "Travellers"}
                </th>
                <th scope="col" className="px-2 py-3">
                  Booking Date
                </th>
              </tr>
            </thead>
            <tbody className="md:text-lg text-[12px] ">
              <tr>
                <th
                  scope="row"
                  className="font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {orderState.title}
                </th>
                <td className="px-2 py-4">{orderState.category}</td>
                <td className="px-2 py-4">{orderState.people}</td>
                <td className="px-2 py-4">{orderState.date}</td>
              </tr>
            </tbody>
          </table>
          <hr id="bill-hr" className="border border-gray-400" />
          <div className="px-2 pt-5 pb-2 grid grid-cols-2">
            <div>
              <p className="text-gray-500 font-semibold">Sub total</p>
              {/* <p className="text-gray-500 font-semibold">GST (5%)</p> */}
            </div>
            <div className="grid justify-items-end">
              <p className="text-gray-500 font-semibold">
                ₹ {orderState.subTotal}
              </p>
              {/* <p className="text-gray-500 font-semibold">₹ {calculatePer()}</p> */}
            </div>
          </div>
          <hr id="" className="border" />
          {orderState.category === "ePooja" && (
            <div className="pt-2 px-4">
              <h3 className="text-lg text-gray-600">Add Ons</h3>
              {orderState?.addOns.map((addon) => {
                return (
                  <div key={addon.id} className="grid grid-cols-2">
                    <div className="grid justify-items-start text-gray-500">
                      {addon.title}
                    </div>
                    <div className="grid justify-items-end text-gray-500">₹{addon.price}</div>
                  </div>
                );
              })}
              <hr id="" className="border mt-1" />
            </div>
          )}
          <div className="px-6 pt-2 pb-2 grid grid-cols-2">
            <div>
              <p className="font-semibold text-2xl">Total</p>
            </div>
            <div className="grid justify-end">
              <p className="font-semibold text-2xl inline-block">
                ₹ {calculateTotal()}
              </p>
            </div>
          </div>

          <div className="pt-5 flex items-center justify-center">
            <button
              disabled={isLoading}
              onClick={handlePayment}
              className="border border-orange-400 bg-orange-400 py-1.5 px-5 text-white transition-all hover:bg-orange-500 duration-300 rounded-lg text-center text-xl w-full mx-7 "
            >
              {isLoading ? (
                <Loader
                  color={"white"}
                  size={23}
                  speed={2.0}
                  stroke={3}
                  bgOpacity={""}
                ></Loader>
              ) : (
                "Make Payment"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
