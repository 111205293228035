import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { FaArrowRight, FaLessThanEqual } from "react-icons/fa";
import Loader from "components/Loader";
import OtpInput from "components/OtpInput";
import { useStateProvider } from "context/StateContext";
import axios from "axios";
import {
  CHECK_USER_ROUTE,
  GET_OTP_ROUTE,
  SIGN_IN_ROUTE,
  VERIFY_OTP_AND_LOGIN,
} from "utils/ApiRoutes";
import { useNavigate } from "react-router-dom";
import { formatPhoneNumber } from "utils/HelperFuncs";
import Cookies from "universal-cookie";
import { v4 as uuidv4 } from "uuid";
import { Button } from "@mui/material";

const Login = () => {
  const navigate = useNavigate();
  const [bool, setbool] = useState(true);
  const [phoneNumber, setphoneNumber] = useState("");
  const [confirmResult, setconfirmResult] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [notify, setnotify] = useState(false);
  const [{ userOtp, prevPage }, dispatch] = useStateProvider();
  const [disBool, setdisBool] = useState(false);
  const [timer, setTimer] = useState(false);
  const [resend, setresend] = useState(false);
  let [tick, settick] = useState(65);

  useEffect(() => {
    let t = setInterval(function () {
      if(t){
        if (tick < 10) {
          settick(`0${(tick -= 1)}`);
        } else {
          settick((tick -= 1));
        }
  
        if (tick < 0) {
          settick("00")
          setresend(true);
          clearInterval(t);
        }
      }
    }, 1000);
  }, [tick]);

  const handlePhoneNumberChange = (e) => {
    setphoneNumber(e);
  };
  const handleResend = () => {
    setTimer(false);
    setconfirmResult(false);
    setbool(true);
  };
  const testOtpVerify = async () => {
    alert("In Test Model Enter OTP : 101010");
    setTimer(true);
    setisLoading(false);
    setdisBool(false);
    setbool(!bool);
    setconfirmResult(true);
    setnotify(true);
  };

  const sendOtp = async () => {
    const formattedNum = formatPhoneNumber(phoneNumber);
    if (formattedNum.length !== 13) {
      alert("Please enter a valid phone number");
      return;
    }
    if (process.env.NODE_ENV === "development") {
      testOtpVerify();
    } else {
      setisLoading(true);
      setdisBool(true);
      try {
        const response = await axios.post(CHECK_USER_ROUTE, { phoneNumber });
        if (!response.data.status) {
          setdisBool(false);
          setisLoading(false);
          alert("User not found for this phone number. Please register first");
          return;
        }
      } catch (error) {
        console.log(error);
        return;
      }
      verifyWithNum();
    }
  };

  const verifyWithNum = async () => {
    const formattedNum = formatPhoneNumber(phoneNumber);
    console.log(formattedNum);
    try {
      const response = await axios.post(GET_OTP_ROUTE, {
        phoneNumber: formattedNum,
      });
      if (response.data.status) {
        setisLoading(false);
        setdisBool(false);
        setbool(!bool);
        setconfirmResult(true);
        setnotify(true);
        alert("OTP has been sent to your Phone Number");
        setTimer(true);
      }
    } catch (error) {
      setdisBool(false);
      setisLoading(false);
      alert("Something went wrong. Please try again in few minutes");
      console.log(error);
      console.log(error.message);
    }
  };

  const handleVirifyOtp = async () => {
    const cookies = new Cookies();
    if (process.env.NODE_ENV === "development") {
      const user = {
        id: "66c8d9617ebe6eafeabfb64f",
        firstName: "Admin",
        lastName: "Test",
        email: "avadhootTest@example.com",
        phoneNumber: "911234567890",
      };

      const accessToken = btoa(JSON.stringify(user));
      cookies.set("accessToken", accessToken, {
        path: "/",
        expires: new Date(new Date().getTime() + 3600 * 24 * 1000),
        sameSite: "strict",
        secure: true,
      });
      sessionStorage.setItem("user", JSON.stringify(user));
      if (prevPage.length) {
        navigate(prevPage);
      } else {
        navigate("/");
      }
    } else {
      const formattedNum = formatPhoneNumber(phoneNumber);

      setisLoading(true);
      setdisBool(true);
      try {
        const { data } = await axios.post(VERIFY_OTP_AND_LOGIN, {
          phoneNumber: formattedNum,
          otp: userOtp,
        });
        if (!data.status) {
          setisLoading(false);
          alert("OTP is incorrect, Please check your OTP and try Again");
          return;
        }

        cookies.set("accessToken", data.accessToken, {
          path: "/",
          expires: new Date(new Date().getTime() + 3600 * 24 * 1000),
          sameSite: "strict",
          secure: true,
        });
        sessionStorage.setItem("user", JSON.stringify(data.user));
        if (prevPage.length) {
          navigate(prevPage);
        } else {
          navigate("/");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <div className="overflow-x-hidden justify-center flex">
      <div style={{ padding: "1px" }} id="recaptcha"></div>
      <div className={`lg:w-[40%] w-[480px] ${timer ? "h-[70vh]" : "h-[60vh]"} mt-32 bg-gradient-to-r from-amber-100 via-orange-100 to-yellow-100 shadow-2xl rounded-md`}>
        <h2 className="mt-3 pt-8 font-bold text-3xl text-orange-500 text-center">
          Welcome Back!
        </h2>
        <h3 className="text-center text-2xl text-gray-400">Please login</h3>

        <PhoneInput
          disabled={disBool}
          onChange={(e) => handlePhoneNumberChange(e)}
          value={phoneNumber}
          className={`mt-8 md:ml-[26%] ml-[12%]`}
          country={"in"}
        />
        {confirmResult && (
          <div className=" flex flex-col items-center justify-center my-3 py-3 text-center">
            <h4 className="text-gray-500 font-semibold pb-1">Enter OTP</h4>
            <OtpInput />
          </div>
        )}
        {bool ? (
          <button
            disabled={disBool}
            onClick={sendOtp}
            className="ml-auto mr-auto mb-0 mt-6 rounded-md font-semibold border border-orange-400 bg-orange-400 py-1.5 px-5 text-white transition-all hover:bg-orange-500 hover:text-white text-center text-2xl font-inter flex items-center justify-center"
          >
            {isLoading ? (
              <Loader
                color={"white"}
                size={21}
                speed={2.0}
                stroke={3}
                bgOpacity={""}
              ></Loader>
            ) : (
              <span className="flex">
                Send OTP
                <FaArrowRight className="ml-2" />
              </span>
            )}
          </button>
        ) : (
          <button
            onClick={handleVirifyOtp}
            className="ml-auto mr-auto mb-0 mt-6 rounded-md font-semibold border border-orange-400 bg-orange-400 py-1.5 px-5 text-white transition-all hover:bg-orange-500 hover:text-white text-center text-2xl font-inter flex items-center justify-center"
          >
            {isLoading ? (
              <Loader
                color={"white"}
                size={21}
                speed={2.0}
                stroke={3}
                bgOpacity={""}
              ></Loader>
            ) : (
              <span className="flex">Verify OTP</span>
            )}
          </button>
        )}

        {timer && (
          <div className="pt-2">
            <h3 className="text-center">
              Request new OTP in <span className="text-red-500">00:{tick}</span>{" "}
            </h3>
            {resend && (
              <div className="text-center">

                <Button onClick={handleResend} variant="text" size="small">
                  Request new
                </Button>
              </div>
            )}
          </div>
        )}
        <h5 className="text-center pt-5">
          New User?{" "}
          <a className="text-purple-800" href="/register">
            Register
          </a>
        </h5>
      </div>
    </div>
  );
};

export default Login;
