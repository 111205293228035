import { reducerCases } from "context/constants";
import { useStateProvider } from "context/StateContext";
import React, { useState } from "react";
import { FaAngleDown } from "react-icons/fa6";
const Dropdown = ({ day, incprice,packageName }) => {
  const [{ premiumPackage, economyPackage }, dispatch] = useStateProvider();
  const [toggleDropdown, setToggleDropdown] = useState(false);

  
  
  const handleChooseVehicle = (vehicle) => {
    let currPackage = {}
    if(packageName === "economyPackage"){
      currPackage=economyPackage
    }else if (packageName === "premiumPackage"){
      currPackage=premiumPackage
    }
    dispatch({
      type: reducerCases.SET_VEHICLE,
      vehicleInfo: {
        vehicle,
        day,
        packageName
      },
    });
    if (currPackage[day].vehicle !== vehicle) {
      if (vehicle === "muv") {
        dispatch({
          type: reducerCases.SET_PACKAGE_PRICE,
          setPrice: {
            packageName,
            op: "inc",
            price: incprice,
          },
        });
      } else {
        dispatch({
          type: reducerCases.SET_PACKAGE_PRICE,
          setPrice: {
            packageName,
            op: "dec",
            price: incprice,
          },
        });
      }
    }
  };
  return (
    <div className="ml-3">
      <div
        onClick={() => setToggleDropdown(!toggleDropdown)}
        className="flex relative cursor-pointer"
      >
        <div className="flex">
          <button className="border p-1">
            Choose Vehicle <FaAngleDown className="inline-block" />
          </button>
          {toggleDropdown && (
            <div className="absolute flex flex-col gap-2 text-center justify-center top-full rounded-lg bg-white  ">
              <ul className="border rounded-sm shadow-lg py-2 text-sm text-gray-700 dark:text-gray-200">
                <li>
                  <p
                    onClick={() => handleChooseVehicle("sedan")}
                    className="inline-block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    Sedan
                  </p>
                </li>
                <li>
                  <p
                    onClick={() => handleChooseVehicle("muv")}
                    className="inline-block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    MUV
                  </p>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
