import ReactGA from 'react-ga4'
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const measurementId = 'G-TQD4HH05F0'
ReactGA.initialize(measurementId);

export const UsePageViews = ()=>{
    const location = useLocation();
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: `${location.pathname + location.search}`, title: "Page Visit" });
    }, [location]);
}
export const trackButtonClicks = (name, category, actions)=>{
    ReactGA.event(name ,{
        category: category,
        action: actions,
        // label: "your label", // optional
        // value: 99, // optional, must be a number
        // nonInteraction: true, // optional, true/false
        // transport: "xhr", // optional, beacon/xhr/image
    });
}