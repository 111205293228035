import React, { useState } from 'react';
import { REGEXP_ONLY_DIGITS } from "input-otp"
import {
    InputOTP,
    InputOTPGroup,
    InputOTPSlot,
  } from "@/components/ui/input-otp"
import { useStateProvider } from 'context/StateContext';
import { reducerCases } from 'context/constants';

const OtpInput = () => {
  const [otp, setotp] = useState("");
  const [{}, dispatch] = useStateProvider();

  const handleChange = (value)=>{
    setotp(value);
    if (value.length === 6){
      dispatch({
        type:reducerCases.SET_USER_OTP,
        userOtp:value
      })
    }
  }
  return (
    <InputOTP onChange={otp=>handleChange(otp)} value={otp} maxLength={6} pattern={REGEXP_ONLY_DIGITS}>
      <InputOTPGroup>
        <InputOTPSlot className="bg-white border border-black" index={0} />
        <InputOTPSlot className="bg-white border-y border-black" index={1} />
        <InputOTPSlot className="bg-white border-y border-black" index={2} />
        <InputOTPSlot className="bg-white border-y border-black" index={3} />
        <InputOTPSlot className="bg-white border-y border-black" index={4} />
        <InputOTPSlot className="bg-white border-y border-black " index={5} />
      </InputOTPGroup>
    </InputOTP>
  )
}

export default OtpInput