import React, { useState } from "react";
import DropDown from "./Dropdown";

import sedan from "assets/images/Sedan_2.webp";
import muv from "assets/images/MUV_2.webp";
import { MdAirlineSeatReclineNormal } from "react-icons/md";
import { CiLocationOn } from "react-icons/ci";
import { useStateProvider } from "context/StateContext";

const ChooseVehicle = ({vehicle, data, price}) => {
    return (
    <div>
      {vehicle==="sedan" ? (

      <div className="py-4 px-2 flex flex-col md:flex-row md: gap-x-8">
        <div className="p-2 border rounded-xl inline-block">
            <img width={200} src={sedan} alt="" />
        </div>
        <div className="leading-6">
            <h1 className="text-2xl font-bold">Swift Dzire or Similar</h1>
            <h3><MdAirlineSeatReclineNormal className="inline-block p-0 text-xl"/> 4 Seater | AC | 2 Luggage bags </h3>
            <h3> <CiLocationOn className="inline-block"/>{data}</h3>
            <h3 className="text-green-500  rounded-lg inline-block px-2 bg-[#FFF9D0]">Included with package</h3>
        </div>
      </div>
      ) : (
        <div className="py-4 px-2 flex flex-col md:flex-row md: gap-x-8">
        <div className="p-2 border rounded-xl inline-block">

            <img width={200} src={muv} alt="" />
        </div>
        <div className="leading-6">
            <h1 className="text-2xl font-bold">Ertiga or Similar</h1>
            <h3><MdAirlineSeatReclineNormal className="inline-block p-0 text-xl"/> 5 Seater | AC | 4 Luggage bags </h3>
            <h3> <CiLocationOn className="inline-block"/> {data}</h3>
            <h3 className="text-green-500  rounded-lg inline-block px-2 bg-[#FFF9D0]">Price: Rs.{price}</h3>
        </div>
      </div>
      )}
    </div>
  );
};

export default ChooseVehicle;
