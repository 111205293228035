import React from 'react'

const DurationTag = ({data, width, color}) => {
  
  return (
    <div style={{width:`${width.toString()}px`, color:color, borderColor:color}} className={`border inline-block border-sky-600 rounded-md font-semibold mb-3 text-sky-600 text-xs`}>
        <p className={'px-1 py-[2px]'}>{data}</p>
    </div>
  )
}

export default DurationTag