import { dividerClasses } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { PiHandsPrayingDuotone } from "react-icons/pi";

const PackageCard = ({
  width,
  height,
  content,
  src,
  to,
  comingSoon = false,
}) => {
  return (
    <>
      <Link to={to}>
        <div className="package_card relative group packageCardAnim">
          <div className="h-[80%]  overflow-hidden w-[100%] flex justify-center items-center">
            <img src={src} alt="" className="rounded-lg h-[100%] w-[100%] transition-all group-hover:scale-50 duration-200 ease-in-out" />
            {comingSoon && (
              <div className="w-full bg-gray-300 text-center absolute top-[40%] z-10">
                <h3 className="text-gray-600 text-2xl font-semibold">
                  Coming Soon
                </h3>
              </div>
            )}
          </div>
          <div className="absolute bottom-[6px] overflow-hidden bg-white  rounded-lg shadow-xl h-[70px] w-[80%] transition-all duration-300 ease-in-out group-hover:h-[130px] animPlace">
            <p className="flex p-3  text-center mt-2 line-clamp-2 font-bold text-gray-500">
              {content}
            </p>
            <p className="opacity-0 group-hover:opacity-100 w-[100%] h-[60px] py-2 flex justify-center transition-all duration-500 ease-in">
              <PiHandsPrayingDuotone size={30} className="text-white" />
            </p>
          </div>
        </div>
      </Link>
    </>
  );
};

export default PackageCard;
