import React from 'react'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel"

const Gallery = ({images, about, bool=false}) => {
  return (
    <div>
     <Carousel opts={{loop:true}} className={bool? "w-full" : "w-full max-w-xs"}>
        <div className='absolute text-white font-semibold z-[5] text-lg bottom-0 ml-1'>{about}</div>
      <CarouselContent>
        {images.map((_, index) => (
            <CarouselItem key={index}>
            <div className='rounded-md'>
              <img className='shadow-inner rounded-md' src={_} alt="" />
            </div>
          </CarouselItem>
        ))}
      </CarouselContent>
        <CarouselPrevious />
      <CarouselNext />
    </Carousel>

    </div>
  )
}

export default Gallery