export const Temples = [
  {
    id:0,
    name: "Shri Mahakaleshwar Jyotirlinga Temple",
    long_desc:
      "Mahakaleshwar Jyotirlinga, located in Ujjain, Madhya Pradesh, is one of the twelve important temples dedicated to Lord Shiva. Situated on the banks of the holy river Shipra, the temple displays Maratha-style architecture with a crafted shikhara. Daily rituals like Bhasma Aarti, Panchamrit Abhishek and Rudrabhishek are performed here. The important festivals of this temple are Mahashivaratri and Kartik Purnima. The temple is easily accessible by road, rail and air through the Indore airport. The best time to visit Mahakaleshwar Temple is from October to March.",
    darshan_timings: "4:00 AM to 11:00 PM",
    aarti_timings: [
      "Bhasma Aarti - 4:00 AM to 6:00 AM",
      "Naivedya Aarti - 7:30 AM to  8:15 AM",
      "Sandhya Puja - 05:00 PM to 05:45 PM",
      "Sandhya Aarti - 7:00 PM to 7:45 PM",
      "Shayan Aarti - 10:30 PM to 11:30 PM",
    ],
    poojas:[]
  },
  {
    id:1,
    name: "Kal Bhairav Temple",
    long_desc:"The Kaal Bhairav Temple is famous in Ujjain city near the bank of river Shipra in the state of Madhya Pradesh. It is dedicated to Lord Kaal Bhairav, considered a spiritual religious form of Lord Shiva. Lord Kaal Bhairav is like the special protector or guardian of Ujjain. It is said as by worshipping. He holds a long weapon called a trident in one of his hands. Kaal Bhairav can make free from bad karma and negative energy. Alcohol is offered to Kaal Bhairav in the form of Bhog or Prasad. Kaal Bhairav also drinks the wine when it is served to them by pandit.",
    darshan_timings: "6:00 AM to 10:00 PM",
    aarti_timings: [
    ],
    poojas:[]
  },
  {
    id:2,
    name: "Shree Harsiddhi Mata Temple",
    long_desc:"Harsiddhi Mata Temple is a famous Goddess temple in Ujjain, Madhya Pradesh near Rudra Sagar Lake. It is one of the 51 important temples dedicated to the Goddess. Ujjain is also known for the famous Mahakaleshwar Temple, which is well-known around the world. Maa Harsiddhi Devi, also known as 'Mangal Chandika' in ancient times, was the supreme deity for Samrat Vikramaditya. During the festival of Navratri people celebrate with great enthusiasm and strong belief at this temple. The lighting of numerous lamps and candles creates a magnificent and divine atmosphere.",
    darshan_timings: "6:00 AM to 10:00 PM",
    aarti_timings: [
    ],
    poojas:[]
  },
  {
    id:3,
    name: "Mangalnath Temple",
    long_desc:"The Mangalnath Temple is a Hindu temple at Ujjain in Madhya Pradesh, It is a special place of worship dedicated to Mahadeva. The temple is situated on the banks of the Shipra River. It is the Birth Place of Mars(mangala). In ancient times, it was quite popular for a clear view of the planet Mars and hence suitable for astronomical studies. Lord Mahadev or Shiva is the deity worshipped in the temple of Mangalnath. The temple on Tuesdays, which is considered to be the day of Lord Mangal, also celebrates the festivals Navratri and Shivratri.",
    darshan_timings: "6:00 AM to 10:00 PM",
    aarti_timings: [
    ],
    poojas:[]
  },
  
];
