import axios from "axios";
import Loader from "components/Loader";
import { useStateProvider } from "context/StateContext";
import React, { useEffect, useState } from "react";
import { CHECK_PAYMENT_STATUS_PHONEPE, CREATE_ORDER } from "utils/ApiRoutes";
import { FaCheckCircle } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const PaymentCheck = () => {
  const [isLoading, setisLoading] = useState(true);
  const [payStatus, setpayStatus] = useState(false);
  const [payMessage, setpayMessage] = useState("");
  const [redirecting, setRedirecting] = useState(false);
  const [counter, setcounter] = useState(0);
  const currOrder = JSON.parse(sessionStorage.getItem("currOrder"));
  const navigate = useNavigate();
  const user = JSON.parse(sessionStorage.getItem("user"));
  useEffect(() => {
    const checkStatusPhonePe = async () => {
      try {
        const { data } = await axios.post(CHECK_PAYMENT_STATUS_PHONEPE, {
          transactionId: currOrder?.currentTransactionId,
        });
        if (data.status === "PAID") {
          setisLoading(false);
          setpayStatus(true);
          try {
            const paymentDetails = {
              paymentStatus: data.status,
              transactionId: data.transactionId,
              paymentTotal: data.amount,
              method: data.method,
            };
            const order = {
              orderNumber: `#000${Math.random(10)}`,
              title: currOrder?.title,
              subTotal: currOrder?.subTotal,
              total: currOrder.total,
              category: currOrder?.category,
              persons: currOrder.people,
              userId: user?.id,
            };
            const orderResponse = await axios.post(CREATE_ORDER, {
              order,
              paymentDetails,
            });
            setRedirecting(true);
            navigate("/my-orders");
          } catch (error) {
            console.log(error);
          }
        } else if (data.status === "PAYMENT_PENDING") {
          if (counter === 2){
            setisLoading(false);
            navigate("/order-summary")
          }else{
            setTimeout(()=>{
              checkStatusPhonePe();
              setcounter(counter+1)
            },2000)
          }
        } else {
          setpayStatus(false);
        }
      } catch (error) {
        console.log(error);
      }
    };
    checkStatusPhonePe();
  });
  return (
    <div className="mt-10">
      {redirecting ? (
        <div className="text-center">
          <h1 className="text-4xl text-gray-600">
            Redirecting ...
            <div className="ml-4 inline-block">
              <Loader
                color={"gray"}
                size={30}
                speed={2.0}
                stroke={4}
                bgOpacity={""}
              />
            </div>
          </h1>
        </div>
      ) : (
        <div>
          <h1 className="text-3xl font-bold text-gray-600 text-center">
            Please wait while we are confirming your payment.
          </h1>
          <h3 className="text-gray-600 font-semibold text-center text-lg mt-2">
            Please do not refresh this page or click back.
          </h3>
          <div className="text-center mt-6">
            {isLoading ? (
              <Loader
                color={"gray"}
                size={35}
                speed={2.0}
                stroke={4}
                bgOpacity={""}
              />
            ) : payStatus ? (
              <div>
                <h3 className="text-3xl text-gray-500">Payment Recieved</h3>
                <FaCheckCircle className="text-green-500 text-5xl inline-block" />
              </div>
            ) : (
              <div>
                <h3 className="text-3xl text-gray-500">Payment Failed</h3>
                <MdCancel className="text-red-600 text-5xl inline-block" />
                <p>{payMessage}</p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentCheck;
