import Loader from "components/Loader";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";


const Protected = ({ children }) => {
  const [flag, setFlag] = useState(null);
  const cookies = new Cookies();
  const cookie = cookies.get("accessToken");
  const navigate = useNavigate();
  const user = JSON.parse(sessionStorage.getItem("user"))
  useEffect(() => {
    if (cookie && user) {
      setFlag(true)
    }else{
      sessionStorage.removeItem("user");
      navigate("/login");
    }
  },[cookie, user, navigate]);
  if (flag === null){
    <div className="mt-[50%]">
      <Loader color={"black"} size={40} bgOpacity={0.1} speed={0.8} stroke={5}/>
    </div>
  }else{
    return children;
  }
};

export default Protected;
