import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import image1 from "assets/images/Mahakal-Lok-Corridor-inauguration2.webp";
import image2 from "assets/images/ujjain-mahakal-bg.jpg";
import image3 from "assets/images/Poojas/grahShanti.jpeg";
import { MdNavigateNext } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";
import "./Carousel.css";

const Carousel = () => {
  const [activeSlide, setActiveSlide] = useState(0);

  const slideStyles = {
    width: "100%",
    height: "60vh",
    position: "absolute",
    top: 0,
    left: 0,
    transition: "opacity 0.5s ease-in-out",
    zIndex: 0,
    opacity: 0,
  };

  const activeSlideStyle = {
    ...slideStyles,
    opacity: 1,
    zIndex: 1,
  };
  useEffect(() => {
    const intervalId = setInterval(nextSlide, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const nextSlide = () => {
    setActiveSlide((prevIndex) => (prevIndex + 1) % 3);
  };

  const prevSlide = () => {
    setActiveSlide((prevIndex) => (prevIndex - 1 + 3) % 3);
  };

  return (
    <div className="relative w-full md:mb-10 mb-4 mt-0 ">
      <div className="h-[60vh] md:h-[60vh] w-[100%]  overflow-hidden">
        <div style={activeSlide === 0 ? activeSlideStyle : slideStyles}>
          <img
            src={image1}
            alt="Slide 1"
            className="w-[100%] h-[100%]  object-cover shadow-inner"
          />
          <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2">
            <Link to="/browse-packages">
              <button
                className={`bg-orange-500 text-white px-4 py-2 rounded ${
                  activeSlide === 0 ? "highlighted-button" : ""
                }`}
              >
                Book Now - Economy Package
              </button>
            </Link>
          </div>
        </div>
        <div style={activeSlide === 1 ? activeSlideStyle : slideStyles}>
          <img
            src={image2}
            alt="Slide 2"
            className="w-[100%] h-[100%] object-cover shadow-inner"
          />
          <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2">
            <Link to="/browse-packages">
              <button
                className={`bg-orange-500 text-white px-4 py-2 rounded ${
                  activeSlide === 1 ? "highlighted-button" : ""
                }`}
              >
                Book Now - Premium Package
              </button>
            </Link>
          </div>
        </div>
        <div style={activeSlide === 2 ? activeSlideStyle : slideStyles}>
          <img
            src={image3}
            alt="Slide 3"
            className="w-[100%] h-[100%] object-cover shadow-inner"
          />
          <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2">
            <Link to="/browse-poojas">
              <button
                className={`bg-orange-500 text-white px-4 py-2 rounded ${
                  activeSlide === 2 ? "highlighted-button" : ""
                }`}
              >
                Book Now - Pooja
              </button>
            </Link>
          </div>
        </div>
      </div>
      <button
        onClick={prevSlide}
        type="button"
        className="absolute top-0 start-0 z-10 flex items-center justify-center h-full px-4 cursor-pointer "
      >
        <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30">
          <GrFormPrevious className="text-3xl font-bold text-white" />
        </span>
      </button>
      <button
        onClick={nextSlide}
        type="button"
        className="absolute top-0 end-0 z-10 flex items-center justify-center h-full px-4 cursor-pointer"
      >
        <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30">
          {/* <span className="text-4xl font-bold text-orange-500">&gt;</span> */}
          <MdNavigateNext className="text-3xl font-bold text-white" />
        </span>
      </button>
    </div>
  );
};
export default Carousel;
