export const Poojas = [
  {
    id: 0,
    tags: "mangalnath mangal nath",
    img: "assets/images/Poojas/mangalNathBhatPuja.jpg",
    title: "Mangal Dosh Nivaran (Bhat) Puja",
    short_desc: "Alleviate Manglik Dosha for a harmonious life.",
    long_desc:
      "According to astrology, if Mars (Mangal) is positioned in certain houses in a birth chart, the person is considered Manglik. This can cause various issues. Mangal Shanti Puja is performed to mitigate these problems.",
    price: "Start From: ₹ 1500.00",
    place: "Mangal Nath Mandir",
    puja_details:
      "Mangal Dosh Puja is performed to seek relief from the adverse effects of Manglik Dosha.",
    benefit_of_puja: {
      "Fulfillment of Desires":
        "Helps in achieving personal aspirations and goals.",
      "Auspicious Ceremony":
        "Ensures the performance of rituals that bring good fortune.",
      "Land Acquisition": "Facilitates the acquisition of property or land.",
      "Wealth Acquisition": "Attracts financial prosperity and wealth.",
      "Finding a Suitable Match":
        "Assists in finding a compatible life partner.",
      "Royal Fortune or Success":
        "Brings success and favorable outcomes in various endeavors.",
    },
    about_img: "",
    about_puja:
      "The Mangal Dosh Puja typically involves various rituals and offerings to appease Lord Mars and seek his benevolent blessings. The rituals may include the recitation of sacred mantras, performing homams (fire rituals), and offering flowers, fruits, and other sacred items to the deity. The puja is performed with reverence and faith, and it is believed that the sincere devotion and prayers during the puja can help alleviate the malefic effects of Mangal Dosh.",
    about_temple_img: "",
    about_temple:
      "The Mangalnath Temple is a Hindu temple located in the Ujjain city of Madhya Pradesh. It is dedicated to Mahadeva, the guardian deity of the city. Located on the banks of the Shipra River, it is one of the most active temples in the city, visited by hundreds of devotees daily. It is regarded as the birthplace of Mars (Mangala in Hindi), according to the Matsya Purana. The temple is famous for its clear view of the planet, making it suitable for astronomical studies. This temple is renowned for the Mangal Dosh Nivaran Bhaat Puja.",
    faq: {
      "What should I bring to the pooja?":
        "Nothing needs to be brought unless the yajman wants to offer something.",
      "Is there a dress code?": "Traditional attire is recommended.",
      "How early should I arrive?":
        "Please arrive 30 minutes before the pooja time.",
    },
    customer_review: [
      {
        name: "Rahul",
        review: "It was a divine experience!",
        rating: 5,
      },
      {
        name: "Priya",
        review: "Very well organized.",
        rating: 4,
      },
    ],
    disclaimer: "Please arrive 30 minutes befre the pooja  .",
  },
  {
    id: 1,
    tags: "",
    img: "assets/images/Poojas/KaalSarp.jpeg",
    title: "Kaal Sarpa Dosha Puja",
    short_desc: "Remove Kaal Sarpa Dosha for peace and success.",
    long_desc:
      "Kaal Sarpa Dosh occurs when all seven planets are positioned between Ketu and Rahu in a horoscope. This alignment affects the person adversely. To negate these effects, Kaal Sarpa Dosh Puja is performed.",
    price: "Start From: ₹ 2499.00",
    place: "",
    puja_details:
      "Kaal Sarpa Dosha Puja is performed to neutralize the adverse effects of Kaal Sarpa Dosha.",
    benefit_of_puja: {
      "Blessings from Rahu and Ketu":
        "Helps in getting blessings from Rahu and Ketu.",
      "Increased Positivity": "Helps in increasing positivity and optimism.",
      "Good Health": "Promotes good health and well-being.",
      Wealth: "Attracts wealth and financial prosperity.",
      "Sincerity and Positivity":
        "Makes a person very sincere and positive towards their activities.",
    },
    about_img: "",
    about_puja:
      "The ritual of Kaal Sarpa Yoga is performed in two places in India: Nasik and Ujjain. In Ujjain, its significance lies in being conducted at the feet of Baba Mahakal and on the banks of the sacred Shipra River. Performing Kaal Sarpa Shanti Puja grants the blessings of nine different types of snakes. Along with Kaal Sarpa Shanti Puja, Rahu and Ketu Puja open the doors to success. Worshipping a golden idol of a snake invokes the blessings of Goddess Lakshmi.",
    about_temple_img: "",
    about_temple:
      "This pooja is performed at the renowned Mangalnath and Angareshwar temples.",
    faq: {
      "What should I bring to the pooja?":
        "Nothing needs to be brought unless the yajman wants to offer something.",
      "Is there a dress code?": "Traditional attire is recommended.",
      "How early should I arrive?":
        "Please arrive 30 minutes before the pooja time.",
    },
    customer_review: [
      {
        name: "Rahul",
        review: "It was a divine experience!",
        rating: 5,
      },
      {
        name: "Priya",
        review: "Very well organized.",
        rating: 4,
      },
    ],
    disclaimer: "Please arrive 30 minutes befre the pooja  .",
  },
  {
    id: 2,
    tags: "",
    img: "",
    title: "Pitra Dosha Puja",
    short_desc: "Pacify ancestors for a harmonious life.",
    long_desc:
      "Pitra Dosh occurs due to ancestral karma and affects various aspects of life. Pitra Dosh Nivaran Puja is performed to pacify the ancestors and remove the dosh.",
    price: "Start From: ₹ 2100.00",
    place: "",
    puja_details:
      "Pitra Dosha Puja is performed to seek forgiveness and blessings from the ancestors.",
    benefit_of_puja: {
      "Mental and Physical Relief": "Mental and physical concerns disappear.",
      "Career and Personal Life Success":
        "All challenges in the workplace, career, and personal life are overcome.",
      "Delayed Pregnancy": "Removes Dosh involving a delayed pregnancy.",
      "Delayed Marriage": "Removes Dosh associated with a delayed marriage.",
      "Mental Tranquility and Family Harmony":
        "Promotes mental tranquility and harmony within the family.",
      "Healthy Marriage": "Ensures a healthy marriage between partners.",
    },
    about_img: "",
    about_puja:
      "A lump of flour is prepared and worshipped at Pitra Dosh Puja in Ujjain following the prescribed procedures. Then, the Mandal worship is finished. The ancestors also receive a Havan. By doing this, blessings from the ancestors are obtained. Worship, Archana, and Tarpan are carried out in this manner.",
    about_temple_img: "",
    about_temple:
      "As mentioned in Puranas, there are four trees to which we worship for Pitra dosh, but one of the trees is situated in Ujjain on the bank of the Shipra River east of Bhairavgarh. This tree is referred to as the Siddhavat tree which is worshipped by the people to get relief from Pitra dosh.",
    faq: {
      "What should I bring to the pooja?":
        "Nothing needs to be brought unless the yajman wants to offer something.",
      "Is there a dress code?": "Traditional attire is recommended.",
      "How early should I arrive?":
        "Please arrive 30 minutes before the pooja time.",
    },
    customer_review: [
      {
        name: "Rahul",
        review: "It was a divine experience!",
        rating: 5,
      },
      {
        name: "Priya",
        review: "Very well organized.",
        rating: 4,
      },
    ],
    disclaimer: "Please arrive 30 minutes before the pooja time.",
  },
  {
    id: 3,
    tags: "mahakal akaleshwara mahakalpooja",
    img: "",
    title: "Rudrabhishek Puja",
    short_desc: "Invoke Lord Shiva's blessings for health and prosperity.",
    long_desc:
      "Rudrabhishek is a special worship and offering made to Lord Shiva. It is considered very auspicious and believed to invoke Shivji's blessings.",
    price: "Start From: ₹ 10099.00",
    place: "Mahakaleshwara Mandir",
    puja_details:
      "Rudrabhishek Puja involves chanting of mantras and offering holy items to Lord Shiva.",
    benefit_of_puja: {
      Health: "Promotes good health and well-being.",
      Prosperity: "Brings prosperity and success.",
      "Negativity Removal":
        "Vanishes negativity and brings happiness and success.",
      "Mental Clarity": "Promotes a healthy mind and good spirit.",
      "Long-standing Problems": "Removes long-standing problems.",
      "Sin and Affliction Removal": "Washes off sins and afflictions.",
      "Family Harmony": "Brings peace, happiness, and family togetherness.",
      "Astrological Benefits":
        "Removes the evil effects of Punarvasu, Ashlesha Nakshatras, and Pushya.",
    },
    about_img: "",
    about_puja:
      "Ujjain Mahakaleshwar Rudrabhishek Puja is a ritual where Panchamrut Pooja is offered to Lord Mahakaleshwar with powerful hymns and mantras to fulfill all the wishes of the person who gets it performed. This Abhishekha bestows prosperity, fulfills all desires, removes negativity, cuts off negative karma, and gives all-round happiness in life. The Panchamrut pooja contains Milk, Curd, Ghee, Honey, and Sugar. The chanting of Sanskrit shlokas (Suktas) during the Abhishekha heals the mind and brings peace.",
    about_temple_img: "",
    about_temple:
      "The Mahakal temple is one of the oldest shrines in India, housing the Mahakaleshwar Jyotirlinga. It is believed to be self-manifested and is revered as a sacred site where Lord Shiva blesses his devotees.",
    faq: {
      "What should I bring to the pooja?":
        "Nothing needs to be brought unless the yajman wants to offer something.",
      "Is there a dress code?": "Traditional attire is recommended.",
      "How early should I arrive?":
        "Please arrive 30 minutes before the pooja time.",
    },
    customer_review: [
      {
        name: "Rahul",
        review: "It was a divine experience!",
        rating: 5,
      },
      {
        name: "Priya",
        review: "Very well organized.",
        rating: 4,
      },
    ],
    disclaimer: "Please arrive 30 minutes before the pooja time.",
  },
  {
    id: 4,
    tags: "mahakal akaleshwara mahakalpooja",
    img: "",
    title: "Laghu Rudrabhishek",
    short_desc: "Invoke Lord Shiva's blessings for devotion and prosperity.",
    long_desc:
      "Laghu Rudra Abhishek is a ritual dedicated to Lord Shiva, involving the chanting of mantras and offering Panchamrut (milk, curd, honey, ghee, and sugar) to the Shivling.",
    price: "Start From: ₹ 20999.00",
    place: "Mahakaleshwara Mandir",
    puja_details:
      "Laghu Rudrabhishek involves detailed rituals to seek blessings from Lord Shiva.",
    benefit_of_puja: {
      Devotion: "Increases devotion and spiritual connection.",
      Prosperity: "Brings prosperity and success.",
      "Negativity Removal":
        "Minimizes the malefic effects of Nakshatras and purifies negative karma.",
      "Peace and Health": "Brings peace, prosperity, and good health.",
    },
    about_puja:
      "Laghu Rudra Abhishek in Ujjain is a ritual done for worshipping Lord Shiva where chanting of mantras is done. In this ritual, Panchamrut (milk, curd, honey, ghee, and sugar) is offered to the Shivling.",
    about_temple:
      "The pooja is conducted at the Mahakal temple in Ujjain, which is one of the oldest shrines in India, housing the Mahakaleshwar Jyotirlinga. It is believed to be self-manifested and is revered as a sacred site where Lord Shiva blesses his devotees.",
    faq: {
      "What should I bring to the pooja?":
        "Nothing needs to be brought unless the yajman wants to offer something.",
      "Is there a dress code?": "Traditional attire is recommended.",
      "How early should I arrive?":
        "Please arrive 30 minutes before the pooja time.",
    },
    customer_review: [
      {
        name: "Rahul",
        review: "It was a divine experience!",
        rating: 5,
      },
      {
        name: "Priya",
        review: "Very well organized.",
        rating: 4,
      },
    ],
    disclaimer: "Please arrive 30 minutes before the pooja time.",
  },
  {
    id: 5,
    tags: "mahakal  akaleshwara mhakalpooja",
    img: "",
    title: "Mahamrutyunjay Jaap (1.25 Lakh jaap)",
    short_desc:
      "Invoke Lord Shiva's blessings for rejuvenation, health, and prosperity.",
    long_desc:
      "Mahamrutyunjay Jaap is a powerful ritual dedicated to Lord Shiva, involving the extensive chanting of the Mahamrutyunjay mantra 1.25 lakh times. This jaap seeks to invoke the blessings of Lord Shiva for health, protection, and prosperity.",
    price: "Start From: ₹ 31000.00",
    place: "Mahakaleshwara Mandir",
    puja_details:
      "Mahamrutyunjay Jaap involves chanting the powerful Mahamrutyunjay mantra 1.25 lakh times to seek Lord Shiva's blessings for health, prosperity, and protection from negative forces.",
    benefit_of_puja: {
      Health: "Promotes good health and well-being.",
      Prosperity: "Brings prosperity and success.",
      "Overcoming Fear of Death": "Helps in overcoming the fear of death.",
      "Negative Energy Removal":
        "Removes negative energy from the life of the devotees.",
    },
    about_img: "",
    about_puja:
      "This mantra is believed to rejuvenate, bestow health, wealth, long life, peace, prosperity, and contentment. The prayer is addressed to Lord Shiva. By chanting this mantra, divine vibrations are generated, which ward off all the negative and evil forces and create a powerful protective shield. Known as the Moksha mantra, it brings the devotee closer to Lord Shiva and destroys fear. The Mahamrutyunjay mantra was formulated by Shukracharya, considered the guru of demons. The mantra is in praise of Lord Shiva. No one can define the kindness, powers, and greatness of Lord Shiva. Jaap or chanting is a spiritual practice of dedicating a mantra, usually a specific number of times, such as 108, while counting the time on a set of beads called a jaap mala. Concentrating on the meaning of the mantra during the chant is a form of devotional worship of the mind. The Mahamrutyunjay mantra connects devotees to Lord Shiva.",
    about_temple_img: "",
    about_temple:
      "The pooja is performed at the revered Mahakal temple in Ujjain, which is one of the oldest shrines in India, housing the Mahakaleshwar Jyotirlinga. It is believed to be self-manifested and is revered as a sacred site where Lord Shiva blesses his devotees.",
    faq: {
      "What should I bring to the pooja?":
        "Nothing needs to be brought unless the yajman wants to offer something.",
      "Is there a dress code?": "Traditional attire is recommended.",
      "How early should I arrive?":
        "Please arrive 30 minutes before the pooja time.",
    },
    customer_review: [
      {
        name: "Rahul",
        review: "It was a divine experience!",
        rating: 5,
      },
      {
        name: "Priya",
        review: "Very well organized.",
        rating: 4,
      },
    ],
    disclaimer: "Please arrive 30 minutes befre the pooja  .",
  },
  {
    id: 6,
    tags: "",
    img: "",
    title: "Rinmukti Abhishek Poojan",
    short_desc: "Seek relief from debts and financial difficulties.",
    long_desc:
      "Rinmukti Abhishek Poojan is a powerful ritual dedicated to Lord Shiva, performed to seek relief from debts and financial difficulties. The ritual involves offering gram dal (चने की दाल) and chanting mantras for Lord Shiva's blessings.",
    price: "Start From: ₹ 2100.00",
    place: "",
    puja_details:
      "Rinmukti Abhishek Poojan involves offering gram dal to Lord Shiva and chanting mantras to seek his blessings for debt relief and financial prosperity.",
    benefit_of_puja: {
      "Debt Relief": "Helps in solving financial difficulties and debts.",
      Prosperity:
        "Invokes blessings from Lord Ganesh and Goddess Lakshmi for wealth and prosperity.",
      "Peace of Mind":
        "Blesses devotees with a peaceful mind and positive spirit.",
    },
    about_img: "",
    about_puja:
      "One gets rid of all the debts of oneself and the ancestors by performing Rudra-Abhishekan from gram dal (चने की दाल) at the Rin-Mukteshwar Mahadev Temple.",
    about_temple_img: "",
    about_temple:
      "The Rinmukteshwar Mahadev temple is situated under a tree on the banks of the sacred Shipra River. Near this temple is a Hanuman temple, where devotees also find the idol of Makaradhwaj, Hanumanji's son. Devotees offer yellow worship materials to Lord Rinmukteshwar for debt relief. The temple is ancient and is believed to be the place where King Harishchandra was freed from his debts. Lord Krishna is said to have recited the Shiv Sahasranamavali to Sudama here.",
    faq: {
      "What should I bring to the pooja?":
        "Nothing needs to be brought unless the yajman wants to offer something.",
      "Is there a dress code?": "Traditional attire is recommended.",
      "How early should I arrive?":
        "Please arrive 30 minutes before the pooja time.",
    },
    customer_review: [
      {
        name: "Rahul",
        review: "It was a divine experience!",
        rating: 5,
      },
      {
        name: "Priya",
        review: "Very well organized.",
        rating: 4,
      },
    ],
    disclaimer: "Please arrive 30 minutes efore the poo ime.",
  },
  {
    id: 7,
    tags: "",
    img: "assets/images/Poojas/chandraShanti.jpeg",
    title: "Chandra Shanti Puja",
    short_desc: "Harmonize your mind and emotions with Chandra Shanti Puja.",
    long_desc:
      "Chandra Shanti Puja is performed to pacify the Moon and alleviate its negative influences on your life. It is believed that the Moon governs emotions, mind, and maternal instincts. If the Moon is adversely placed in a birth chart, it can lead to various challenges. This puja helps to balance the Moon's energy and bring harmony to one's life.",
    price: "Start From: ₹ 2500.00",
    place: "",
    puja_details:
      "Chandra Shanti Puja involves specific rituals and offerings to appease the Moon and seek its blessings.",
    benefit_of_puja: {
      "Mental Peace and Power": "It will give mental peace and power.",
      "Blessings of God Shiva":
        "It helps in getting the blessings of the Goddess Parvati and Lord Shiva.",
      "Enhance Positive Effect of Moon":
        "Chandra Puja will help to enhance the positive effect of the Moon in your birth chart.",
      "Improved Health": "The Chandra Puja can improve your health.",
      "Overcome Stress and Obstacles":
        "It helps the individual overcome the problems of stress, obstacles, and misfortunes.",
      "Spiritual Attainments":
        "It is the most powerful Puja for dhyana/meditation and spiritual attainments.",
      "Peace of Mind":
        "Chandra Grah Shanti Puja can help gain peace of mind and remove negative thoughts.",
      "Marital Harmony":
        "The Chandra Grah Shanti Puja ensures a native has a happy and wealthy married life.",
      "Stability and Social Status":
        "Chandra Grah Shanti Puja is best if you want to overcome the problems of a malefic moon. It is also helpful if you want stability in life, social status, and good relations.",
    },
    about_img: "",
    about_puja:
      "The process involves the following steps:\n\n1. The ritual space and the devotee are purified through the chanting of mantras and the sprinkling of holy water (Ganga Jal), creating a sacred atmosphere.\n\n2. The priest (Pandit ji) invokes the presence of Chandra through elaborate prayers and mantras, inviting the positive energies associated with the Moon.\n\n3. Devotees present offerings such as flowers, grains, and sacred substances to Chandra as a gesture of respect and devotion.\n\n4. Specific Vedic mantras, including the Chandra Beej Mantra 'Om Shram Shreem Shrom Sah Somay Namah || ( ओम श्रां श्रीं श्रौं सः सोमाय नमः || )', hold particular significance and are recited to appease the Moon and neutralize any negative influences.\n\n5. The ceremony includes a havan, a fire ritual symbolizing purification and transformation. Offerings are made to the sacred fire, generating positive vibrations.",
    about_temple_img: "",
    about_temple:
      "The Chandra Shanti Puja can be performed at any temple dedicated to the Moon or at a place of worship chosen by the individual. In Ujjain, several temples offer this puja, and it is often performed on the banks of the Shipra River to harness the purifying energy of the water.It takes around 2.5 to 3 hours",
    faq: {
      "What should I bring to the pooja?":
        "Nothing needs to be brought unless the yajman wants to offer something.",
      "Is there a dress code?": "Traditional attire is recommended.",
      "How early should I arrive?":
        "Please arrive 30 minutes before the pooja time.",
    },
    customer_review: [
      {
        name: "Rahul",
        review: "It was a divine experience!",
        rating: 5,
      },
      {
        name: "Priya",
        review: "Very well organized.",
        rating: 4,
      },
    ],
    disclaimer: "Please arrive 30 minutes before the pooja time.",
  },
  {
    id: 8,
    tags: "jaap maabaglaki dhaam",
    img: "assets/images/Poojas/maa_baglamukji_jaap_havan.jpeg",
    title: "Maa Baglamukhi Jaap Anusthan Poorna Ahuti Havan",
    short_desc:
      "Invoke the blessings of Maa Baglamukhi for protection and victory.",
    long_desc:
      "Maa Baglamukhi Jaap Anusthan Poorna Ahuti Havan is performed to seek the blessings of Maa Baglamukhi, the goddess of power and victory. This anusthan is particularly effective in reversing the negative effects of black magic and negativity. It involves chanting specific mantras, making offerings, and performing a havan to invoke the goddess's divine energy for protection and victory.",
    price: "Start From: ₹ 25000.00",
    place: "Maa Baglamukhi Dhaam",
    puja_details:
      "Maa Baglamukhi Jaap Anusthan Poorna Ahuti Havan involves chanting of mantras and performing rituals to seek the blessings of the goddess for protection and victory.",
    benefit_of_puja: {
      "Victory over Enemies":
        "Baglamukhi Anusthan helps in seeking the blessings of Goddess Baglamukhi for victory over enemies, both physical and spiritual.",
      "Protection and Shield":
        "The practice of Baglamukhi Anusthan creates a powerful shield of protection against negative forces, black magic, and evil intentions.",
      "Health and Well-being":
        "By performing Baglamukhi Anusthan, one can seek the blessings of Maa Baglamukhi for improved physical and mental health.",
      "Wealth and Prosperity":
        "Baglamukhi Anusthan attracts abundance and prosperity into one's life with the blessings of Maa Baglamukhi.",
      "Inner Strength & Confidence":
        "Baglamukhi Anusthan empowers individuals with inner strength, courage, and confidence to overcome obstacles and challenges.",
      "Resolution of Legal Issues":
        "Baglamukhi Anusthan seeks divine intervention in legal matters, bringing clarity, fairness, and success in legal proceedings.",
      "Harmonious Relationships":
        "Baglamukhi Anusthan helps in resolving conflicts, misunderstandings, and disharmony in relationships, promoting peace and love.",
      "Protection from Negativity":
        "Through Baglamukhi Anusthan, individuals are shielded from the evil eye, negative energies, and psychic attacks.",
      "Overcoming Obstacles":
        "Baglamukhi Anusthan helps in removing obstacles and hurdles that may be hindering progress in life.",
      "Clarity of Thought & Speech":
        "Baglamukhi Anusthan enhances clarity of thought and speech, improving communication skills and decision-making.",
      "Protection from Accidents ":
        "By performing Baglamukhi Anusthan, individuals receive protection from accidents, mishaps, and unforeseen dangers.",
      "Improvement in Academic Pursuit":
        "Baglamukhi Anusthan enhances concentration, memory, and academic excellence for students.",
      "Removal of Negative Influences":
        "Baglamukhi Anusthan purifies the energy field and removes negative influences that may affect one's life.",
      "Enhanced Intuition and Wisdom":
        "Baglamukhi Anusthan deepens intuition and wisdom, aiding in making intuitive decisions and gaining insights.",
      "Inner Transformation ":
        "Baglamukhi Anusthan facilitates inner transformation and self-realization, leading to a deeper understanding of oneself.",
      "Protection from Psychic Attacks":
        "Through Baglamukhi Anusthan, individuals are protected from psychic attacks, negative energies, and spiritual disturbances.",
      "Increased Willpower ":
        "Baglamukhi Anusthan instills strong willpower and determination to overcome obstacles and achieve goals.",
      "Spiritual & Personal Growth":
        "The practice of Baglamukhi Anusthan leads to overall spiritual and personal growth by aligning with one's higher self and realizing true potential.",
    },
    about_img: "",
    about_puja:
      "The process involves the following steps:\n\n1. The ritual space and the devotee are purified through the chanting of mantras and the sprinkling of holy water (Ganga Jal), creating a sacred atmosphere.\n\n2. The priest (Pandit ji) invokes the presence of Maa Baglamukhi through elaborate prayers and mantras, inviting the positive energies associated with the goddess.\n\n3. Devotees present offerings such as flowers, grains, and sacred substances to Maa Baglamukhi as a gesture of respect and devotion.\n\n4. Specific Vedic mantras are chanted to appease Maa Baglamukhi and neutralize any negative influences.\n\n5. The ceremony includes a havan, a fire ritual symbolizing purification and transformation. Offerings are made to the sacred fire, generating positive vibrations.",
    about_temple_img: "",
    about_temple:
      "Maa Baglamukhi Temple located in Ujjain, Madhya Pradesh, is one of the prominent religious sites in India and holds special significance in Hinduism. Maa Baglamukhi is one of the ten Mahavidyas, and she is considered the deity of stambhana shakti, meaning she has the power to immobilize enemies. This temple in Ujjain is particularly important for devotees facing obstacles in life and seeking authority, victory, and protection. Worship and rituals dedicated to Maa Baglamukhi are performed here, including special chants, tantric practices, and homa rituals. Devotees also offer special prayers here to fulfill their desires. The entire process takes around 3 to 4 hours.",
    faq: {
      "What should I bring to the pooja?":
        "Nothing needs to be brought unless the yajman wants to offer something.",
      "Is there a dress code?": "Traditional attire is recommended.",
      "How early should I arrive?":
        "Please arrive 30 minutes before the pooja time.",
    },
    customer_review: [
      {
        name: "Arjun",
        review: "A powerful and enlightening experience!",
        rating: 5,
      },
      {
        name: "Anjali",
        review: "Felt very positive and energized.",
        rating: 4,
      },
    ],
    disclaimer: "Please arrive 30 minutes before the pooja time.",
  },
  {
    id: 9,
    tags: "guruchandaldosh shrapitdosh",
    img: "assets/images/Poojas/rahuKetuPooja.jpeg",
    title: "Rahu Ketu Shanti Puja",
    short_desc:
      "Perform Rahu Ketu Shanti Puja to mitigate the adverse effects of Rahu and Ketu.",
    long_desc:
      "Sampoorna Rahu Ketu Shanti Puja is a powerful Vedic ritual aimed at pacifying the malefic effects of Rahu and Ketu, the shadow planets in Jyotisha Shastra. This puja is revered for its ability to bring stability, peace, and prosperity to those affected by the adverse positions of these planets in their horoscopes. Performed in the sacred city of Ujjain, this puja involves specific rituals, mantras, and offerings to nullify the negative impacts and invite positive outcomes into one's life.",
    price: "Start From: ₹ 20000.00",
    place: "",
    puja_details:
      "Sampoorna Rahu Ketu Shanti Puja involves chanting specific Vedic mantras, making offerings, and performing rituals to pacify the shadow planets Rahu and Ketu. This puja aims to mitigate their adverse effects and bring stability and prosperity.",
    benefit_of_puja: {
      "Nullifying Malefic Effects":
        "The primary purpose of Sampoorna Rahu Ketu Shanti is to mitigate the negative impacts caused by Rahu and Ketu. It helps in alleviating problems related to health, finance, relationships, and mental peace.",
      "Protection from Misfortunes":
        "Performing this puja offers protection against accidents, unexpected losses, and other misfortunes. It creates a shield of positive energy around the individual.",
      "Improving Personal Life":
        "The puja helps in removing obstacles in both personal and professional lives. It is especially beneficial for those facing delays in marriage, childbirth, or career advancement.",
      "Spiritual Growth":
        "This puja aids in spiritual development by pacifying Rahu and Ketu. Individuals often experience a heightened sense of spirituality and improved focus on personal growth and enlightenment.",
    },
    about_img: "",
    about_puja:
      "The Sampoorna Rahu Ketu Shanti Puja involves:\n\n1. Purification of the ritual space and the participants through mantras and holy water to create a sacred atmosphere.\n\n2. Invocation of Rahu and Ketu through specific prayers and mantras to bring their positive energies.\n\n3. Offering of sacred substances such as flowers, grains, and other items as a gesture of devotion.\n\n4. Chanting of Vedic mantras and performing rituals to neutralize the malefic effects of Rahu and Ketu.\n\n5. Conducting a havan (fire ritual) to symbolize purification and transformation, offering oblations to the sacred fire to generate positive vibrations.",
    about_temple_img: "",
    about_temple:
      "Ujjain, located in Madhya Pradesh, is a major holy city in Hinduism. It is considered a key site for performing spiritual rituals due to its significant religious heritage. The city hosts numerous temples and is associated with powerful deities such as Lord Shiva, Lord Kalabhairava, and Goddess Mahakali. Ujjain is also known for its role in Vedic astrology as the central point for calculating times and calendars. Performing Rahu Ketu Shanti Puja in this sacred city is believed to amplify the positive effects of the ritual.",
    faq: {
      "What should I bring to the puja?":
        "Nothing needs to be brought unless you wish to offer something specific.",
      "Is there a dress code?": "Traditional attire is recommended.",
      "How early should I arrive?":
        "Please arrive 30 minutes before the puja time.",
    },
    customer_review: [
      {
        name: "Ravi",
        review:
          "The puja was well-conducted, and I feel a significant change in my life.",
        rating: 5,
      },
      {
        name: "Sita",
        review:
          "A deeply spiritual experience. I felt a sense of peace and clarity.",
        rating: 4,
      },
    ],
    disclaimer: "Please arrive 30 minutes before the puja time.",
  },
  {
    id: 10,
    tags: "kumbhavivaha arkavivaha angarakadosha vishadosha",
    img: "assets/images/Poojas/grahShanti.jpeg",
    title: "Navgraha Shanti Puja",
    short_desc:
      "Perform Navgraha Shanti Puja to mitigate the negative effects of all nine planets.",
    long_desc:
      "Navgraha Shanti Puja is a revered Vedic ritual performed to honor the nine celestial deities: Sun, Moon, Mars, Mercury, Jupiter, Venus, Saturn, Rahu, and Ketu. This puja is designed to alleviate the negative impacts of these planets and enhance their positive influences on one’s life. Conducted in the sacred city of Ujjain, the puja involves specific mantras, offerings, and rituals to bring balance, prosperity, and peace into one's life.",
    price: "Start From: ₹ 3100.00",
    place: "",
    puja_details:
      "Navgraha Shanti Puja involves the worship of all nine planetary deities to reduce their negative effects and increase their beneficial influences. This puja includes chanting specific Vedic mantras, offering sacred substances, and performing rituals to harmonize the planetary influences.",
    benefit_of_puja: {
      "Health and Wellness":
        "Keeps illnesses at bay, enhances wellness, and increases immunity.",
      Protection: "Guards against robbery, accidents, and premature death.",
      "Intellectual Growth":
        "Increases intellect, memory capacity, and mental clarity.",
      "Inner Strength":
        "Enhances inner strength, confidence, and helps achieve desired outcomes.",
      "Prosperity and Success":
        "Brings prosperity, harmony, and success in all aspects of life.",
      "Elimination of Doshas":
        "Removes Vastu Doshas, ensuring a harmonious living environment.",
      Longevity:
        "Promotes longevity and protects against unexpected misfortunes.",
      "Virtuous Offspring":
        "Blesses with virtuous children who uphold the family name.",
    },
    about_img: "",
    about_puja:
      "The Navgraha Shanti Puja involves:\n\n1. Purification of the ritual space and participants with mantras and holy water to create a sacred environment.\n\n2. Invocation of the nine planetary deities through specific prayers and mantras to bring their positive energies.\n\n3. Offering of sacred substances like flowers, grains, and other items as a gesture of devotion to each deity.\n\n4. Chanting of Vedic mantras and performing rituals to neutralize the negative effects of the planets and enhance their positive influences.\n\n5. Conducting a havan (fire ritual) to symbolize purification and transformation, offering oblations to the sacred fire to generate positive vibrations.",
    about_temple_img: "",
    about_temple:
      "Ujjain, a major holy city in Madhya Pradesh, is renowned for its spiritual significance and is a key location for performing various Vedic rituals. The city’s temples and sacred sites provide an ideal setting for conducting the Navgraha Shanti Puja, believed to amplify the positive effects of the ritual and bring balance to one's life.",
    faq: {
      "What should I bring to the puja?":
        "Nothing needs to be brought unless you wish to offer something specific.",
      "Is there a dress code?": "Traditional attire is recommended.",
      "How early should I arrive?":
        "Please arrive 30 minutes before the puja time.",
    },
    customer_review: [
      {
        name: "Kiran",
        review:
          "A deeply impactful ritual that brought much-needed balance to my life.",
        rating: 5,
      },
      {
        name: "Meera",
        review:
          "The puja was performed with great devotion, and I feel a significant improvement in my circumstances.",
        rating: 4,
      },
    ],
    disclaimer: "Please arrive 30 minuts before the   time.",
  },
  {
    id: 11,
    tags: "",
    img: "assets/images/Poojas/mahamrityumJaap.jpeg",
    title: "Maha Mrityunjaya Jaap",
    short_desc:
      "Perform Maha Mrityunjaya Jaap to seek victory over death and enhance longevity.",
    long_desc:
      "Maha Mrityunjaya Jaap is a powerful Vedic ritual dedicated to Lord Shiva, aimed at preventing untimely death and overcoming major illnesses. This jaap, performed on auspicious days, involves chanting the Maha Mrityunjaya mantra to invoke Lord Shiva’s blessings for health, longevity, and protection. Conducted in the sacred city of Ujjain, this ritual rejuvenates and bestows health, wealth, and contentment.",
    price: "Start From: ₹ 15000.00",
    place: "",
    puja_details:
      "Maha Mrityunjaya Jaap involves chanting Lord Shiva's Maha Mrityunjaya mantra 108 times, performing abhisheka with milk, and offering flowers, fruits, and other sacred substances. The ritual includes Gauri Ganesh Puja, Kalash Navgraha Puja, and a havan (fire ritual) to enhance the benefits of the jaap.",
    benefit_of_puja: {
      "Victory Over Death":
        "The jaap helps in preventing untimely and unnatural death, offering protection against severe dangers.",
      "Removal of Kaal Sarp Dosh":
        "Effective in removing the malefic effects of Kaal Sarp Dosh, improving life conditions.",
      "Elimination of Karmic Dosh":
        "Helps in clearing karmic doshas and leading a better life.",
      "Health and Longevity":
        "Aids in recovering from major illnesses and extends the lifespan of an individual.",
      "Rejuvenation and Peace":
        "Bestows rejuvenation, peace, prosperity, and contentment by invoking Lord Shiva’s blessings.",
    },
    about_img: "",
    about_puja:
      "The Maha Mrityunjaya Jaap involves:\n\n1. Starting with Gauri Ganesh Puja and Kalash Navgraha Puja to purify the ritual space and participants.\n\n2. Chanting the Maha Mrityunjaya mantra 108 times using a japamala (rosary).\n\n3. Performing abhisheka (ritual bath) with milk on Shiva Ling, offering flowers, deepam, water, fruits, bel leaves, and incense sticks.\n\n4. Conducting a havan (fire ritual) to complete the jaap and generate positive vibrations.\n\n5. The ritual aims to remove severe dangers, improve health, and enhance longevity.",
    about_temple_img: "",
    about_temple:
      "Ujjain, a prominent holy city in Madhya Pradesh, is renowned for its spiritual significance and is an ideal location for performing Maha Mrityunjaya Jaap. The city's sacred temples and spiritual heritage make it a powerful site for this ritual, believed to amplify its benefits and bring divine blessings.",
    faq: {
      "What should I bring to the puja?":
        "Nothing needs to be brought unless you wish to offer something specific.",
      "Is there a dress code?": "Traditional attire is recommended.",
      "How early should I arrive?":
        "Please arrive 30 minutes before the puja time.",
    },
    customer_review: [
      {
        name: "Sanjay",
        review:
          "A profound experience that brought peace and health to my life.",
        rating: 5,
      },
      {
        name: "Pooja",
        review:
          "The jaap was performed with great devotion, and I feel a positive change.",
        rating: 4,
      },
    ],
    disclaimer: "Please arrive 30 minutes before the puja time.",
  },
];

export const getPoojaMap = () => {
  const poojaMap = new Map();
  Poojas.forEach((pooja) => {
    poojaMap.set(pooja.id, pooja);
  });
  return poojaMap;
};
