import ControlButton from "components/ControlButton";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useState } from "react";
import { getFullDate } from "utils/HelperFuncs";
import dayjs from "dayjs";
import { useStateProvider } from "context/StateContext";
import { reducerCases } from "context/constants";
import { useLocation, useNavigate } from "react-router-dom";
import poojaLogo from "assets/icons/pooja.png";
import PoojaImages from "assets/images/Poojas/PoojaImages";
import { trackButtonClicks } from "Analytics/GoogleAnalytics";

const PoojaBooking = () => {
  const today = getFullDate();
  const [date, setdate] = useState(today);
  const [yajmans, setYajmans] = useState("0");
  let currentPooja = JSON.parse(localStorage.getItem("currentPooja"));
  let [{}, dispatch] = useStateProvider();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleChange = (e) => {
    let bookingDate = dayjs(`${e.$y}-${e.$M + 1}-${e.$D}`);
    setdate(bookingDate);
  };

  const handleBookingClick = (e) => {
    trackButtonClicks(
      "Pooja_Booking_payment_btn",
      "custom_event",
      `To payment for ${currentPooja.title}`
    );
    e.preventDefault();
    const { $y: year, $M: month, $D: day } = date;

    if (parseInt(yajmans) > 0) {
      let s_arr = currentPooja.price.split(" ");
      let toPay = parseInt(s_arr[s_arr.length - 1]);
      sessionStorage.setItem("currOrder", JSON.stringify(
        {
          date:`${day}-${month+1}-${year}`,
          people: yajmans,
          category: "Pooja",
          title: currentPooja.title,
          subTotal: parseInt(yajmans) * toPay,
          orderObj:currentPooja
        }
      ))

      dispatch({
        type: reducerCases.SET_CURRENT_ORDER,
        order: {
          date:`${day}-${month+1}-${year}`,
          people: yajmans,
          category: "Pooja",
          title: currentPooja.title,
          subTotal: parseInt(yajmans) * toPay,
          orderObj:currentPooja
        },
      });
      dispatch({
        type: reducerCases.SET_PREV_PAGE,
        prevPage: location.pathname,
      });
      navigate("/order-summary");
    } else {
      alert("Need 1 or more Yajmans for Pooja");
      return;
    }
  };

  return (
    <div>
      {/* Pooja Content */}
      <div className="mb-5 pb-4">
        <div className="my-2 text-center py-5">
          <h2 className="text-orange-500 font-semibold text-4xl">
            {currentPooja?.title}
          </h2>
          <div className="md:mx-20 mx-10 rounded-lg my-5 text-center inline-block">
            <img className="rounded-lg shadow-xl" width={400} src={PoojaImages[currentPooja.id] ? PoojaImages[currentPooja.id] : poojaLogo} alt={currentPooja.title} />
          </div>
        </div>
        <div className="md:mx-20 mx-10 rounded-lg mt-2 mb-10 text-center py-5 border border-orange-500">
          <form action="" className="">
            <div className="flex lg:flex-row font-medium flex-col items-center justify-center">
              <ControlButton yajmans={yajmans} setYajmans={setYajmans} />

              <div className="lg:mx-5 lg:px-5 my-5 py-5">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    // onChange=""
                    value={date}
                    onChange={(e) => handleChange(e)}
                    disablePast
                    minDate={today}
                    className="rounded-lg"
                    label="Choose Date"
                  />
                </LocalizationProvider>
              </div>
            </div>
              <div className="justify-center flex">
                <button
                  onClick={(e) => handleBookingClick(e)}
                  className="book_btn"
                >
                  Book Pooja
                </button>
              </div>
          </form>
        </div>
        <div className="md:mx-20 mx-10 my-5 px-5 py-5 border border-orange-500 shadow-2xl rounded-lg text-center">
          <div className="mb-3">
            <h3 className="title mb-4">Benifits of this Pooja :</h3>
            <ul className="">
              {Object.keys(currentPooja?.benefit_of_puja).map((p_key) => {
                return (
                  <li
                    key={p_key}
                    className="text-xl font-semibold text-gray-700 leading-[20px]"
                  >
                    {p_key} -{" "}
                    <p className="text-sm pb-2 font-normal ">
                      {currentPooja.benefit_of_puja[p_key]}
                    </p>
                  </li>
                );
              })}
            </ul>
          </div>
          <hr className="border mx-10 my-5"/>
          {/* Time Place Duration Requirements */}
          <div className="mb-3 mt-3">
            <h3 className="title">Place of Pooja</h3>
            <h4 className="text-xl font-semibold text-gray-700">
              {currentPooja.place?.length===0?"Ujjain":currentPooja.place}
            </h4>
          </div>
          <hr className="border mx-10 my-5"/>
          <div className="mb-3">
            <h3 className="title">FAQs</h3>
            <ul className="">
              {Object.keys(currentPooja?.faq).map((f_key) => {
                return (
                  <li
                    key={f_key}
                    className="text-xl font-semibold text-gray-700 leading-[20px]"
                  >
                    {f_key}{" "}
                    <p className="text-sm pl-5 pb-2 font-normal">
                      {currentPooja.faq[f_key]}
                    </p>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PoojaBooking;
