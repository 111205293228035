import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1324 },
    items: 4,
    slidesToSlide: 2,
  },
  desktop: {
    breakpoint: { max: 1324, min: 1000 },
    items: 3,
    slidesToSlide: 2,
  },
  tablet: {
    breakpoint: { max: 1000, min: 664 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 664, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const goals = [
  {
    content: `Our goal is to address the challenges travelers encounter during their visit to Ujjain by offering a comprehensive service platform that enables them to seamlessly plan their entire trip to Ujjain.`,
  },
  {
    content: `We aim to leverage our technology to enable people to book any Pooja organised in major temples or places in Ujjain with our online booking platform effortlessly, with just a few clicks.`,
  },
  {
    content: ` We want our customers to have a divine experience and gain a deep understanding of the historical significance of the holy city of Ujjain by offering comprehensive guidance throughout their trip.`,
  },
  {
    content: `We also aim to expand our platform to all major holy cities in India, as travelers encounter similar challenges everywhere. By collaborating with local communities in these cities, we can provide tailored solutions to enhance the travel experience.`,
  },
];

const Goals = () => {
  return (
    <div>
      <div className="myCarouselBtn py-2 min-h-[40vh] flex justify-center flex-col">
        <Carousel
          transitionDuration={500}
          infinite={true}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          autoPlay
          className=" h-[100%] py-8"
          responsive={responsive}
        >
          {goals.map((review) => (
            <div
              key={review.id}
              className="flex-shrink-0 ml-3 mr-3 md:min-h-[300px]  flex justify-center items-start  max-w-md p-4  shadow-xl rounded-lg mx-2 hover:shadow-2xl transition-all duration-200 hover:mt-[-20px] hover:bg-blue-100 "
            >
              <p className="mb-2 flex justify-start flex-col items-start p-4 text-[17px] relative">
                <span className="absolute">&#10077; </span>&nbsp;&nbsp;{" "}
                {review.content}&#10078;
              </p>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Goals;
