import React, { useState } from "react";
import ContentCard from "components/ContentCard";
import poojaLogo from "assets/icons/pooja.png";
import { Poojas } from "Poojas";
import PoojaImages from "assets/images/Poojas/PoojaImages";
import { searchByTitle } from "utils/SearchFunc";
import { IoMdClose } from "react-icons/io";
const BrowsePoojas = () => {
  const [poojas, setPoojas] = useState(Poojas);
  const [searchText, setsearchText] = useState("");
  let timeOut = null
  const handleSearchChange = (e) => {
    e.preventDefault();
    setsearchText(e.target.value);
  };
  const handleKeyUp = (e) => {
    if (timeOut) {
      clearTimeout(timeOut);
    }
    let newPoojas = [];
    let t = setTimeout(() => {
      if (searchText) {
        for (let i = 0; i < Poojas.length; i++) {
          let {title,id,tags} = Poojas[i]
          // poojaTitle = poojaTitle.replace(/\s/g, "")
          if (title.toLowerCase().match(searchText.toLowerCase()) || tags?.match(searchText.toLowerCase())) {
              newPoojas.push(Poojas[i])
          }
          
          
        }

        setPoojas(newPoojas);
      } else {
        setPoojas(Poojas);
      }
    }, 1000);
    timeOut = t;
  };

  return (
    <>
      <div className="grid justify-items-center">
        <h1 className="text-center p-3 font-bold text-orange-500 text-4xl mt-6">
          Poojas in Ujjain
        </h1>
        <input
          placeholder="Search Pooja by name, temple or dosha"
          value={searchText}
          onChange={(e) => handleSearchChange(e)}
          onKeyUp={handleKeyUp}
          required
          className="search_input peer"
          type="text"
        />
        <div className="grid w-full lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 justify-items-center mt-10">
          {poojas.map((pooja, ind) => {
            return (
              <ContentCard
                ind={pooja.id}
                key={pooja.id}
                src={PoojaImages[pooja.id] ? PoojaImages[pooja.id] : poojaLogo}
                title={pooja.title}
                short_desc={pooja.short_desc}
                long_desc={pooja.long_desc}
                price={pooja.price}
                place={pooja.place}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default BrowsePoojas;
