import DurationTag from "components/Tags/DurationTag";
import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import ChooseVehicle from "components/Package/ChooseVehicle";
import { useStateProvider } from "context/StateContext";
import Dropdown from "components/Package/Dropdown";
import { packages, reducerCases } from "context/constants";
import { PiBowlFoodLight } from "react-icons/pi";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import RoomsAndGuests from "components/Package/RoomsAndGuests";
import prop1 from "assets/images/prop1.avif";
import prop2 from "assets/images/prop2.webp";
import prop3 from "assets/images/prop3.jpeg";

import Gallery from "components/Package/Gallery";
import dayjs from "dayjs";
import { getFullDate, getFullDatePackage } from "utils/HelperFuncs";
import { useLocation, useNavigate } from "react-router-dom";
import PickupRadio from "components/PickupRadio";
import { FaWhatsapp } from "react-icons/fa";
import { trackButtonClicks } from "Analytics/GoogleAnalytics";


const EconomyPackage = () => {
  const navigate = useNavigate()
  const today = getFullDatePackage();
  const [{ economyPackage, user }, dispatch] = useStateProvider();
  const [summary, setsummary] = useState(false);
  const [date, setdate] = useState(today);
  const [highlightMessage, setHighlightMessage] = useState(false);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handlePayment = async ()=>{
    trackButtonClicks(
      "Economy_package_payment_btn",
      "custom_event",
      "To payment economy package"
    );
    const {$y:year,$M:month ,$D:day} = date;
    dispatch({
      type:reducerCases.SET_ECONOMY_PACKAGE,
      economyPackage:{
        ...economyPackage,
        date:`${day}-${month}-${year}`
      }
    });
    sessionStorage.setItem("currOrder", JSON.stringify(
      {
        title:"Economy Package",
        category: "Package",
        people:economyPackage.guests,
        date:`${day}-${month}-${year}`,
        subTotal:parseInt(economyPackage.price),
        orderObj:economyPackage
      }
    ))
    dispatch({
      type:reducerCases.SET_CURRENT_ORDER,
      order:{
        title:"Economy Package",
        category: "Package",
        people:economyPackage.guests,
        date:`${day}-${month}-${year}`,
        subTotal:parseInt(economyPackage.price),
        orderObj:economyPackage
      }
    })
    navigate("/order-summary")
  }
  const handleApply = (guests,rooms) =>{
    if (parseInt(guests) > 4) {
      setHighlightMessage(true);
    } else {
      setHighlightMessage(false);
    }
    let xprice;

    if(guests === 3){
      xprice = 9000
    }else if(guests === 2){
      xprice = 6500
    }else if(guests === 1){
      xprice = 5500
    }else if(guests>3){
      if(parseInt(guests)%3===1){
        xprice = parseInt(parseInt(guests)/3) * 9000 + 4000
      }else if(parseInt(guests)%3===2){
        xprice = parseInt(parseInt(guests)/3) * 7000 + 9000
      }else{
        xprice = parseInt(parseInt(guests)/3) * 9000
      }
      // xprice = (parseInt(guests)-3)*2500 + 13500
    }
    if(economyPackage.airportpick){
      xprice+=economyPackage.pickupPrice
    }
    let {dayOne}=economyPackage;
    if (dayOne.vehicle === 'muv'){
      xprice+=economyPackage.muvPrice
    }
    dispatch({
      type:reducerCases.SET_ECONOMY_PACKAGE,
      economyPackage: {
        ...economyPackage,
        guests,
        rooms,
        price:xprice
      }
    })
  }
  const handleDateChange = (e) => {
    let bookingDate = dayjs(`${e.$y}-${e.$M + 1}-${e.$D}`);
    setdate(bookingDate);
    dispatch({
      type:reducerCases.SET_PACKAGE_DATE,
      setDate:{
        date:`${e.$y}-${e.$M + 1}-${e.$D}`,
        packageName:packages.ECONOMY_PACKAGE
      }
    })
  };
  const handleRemoveUjjainDarshan = () => {
    let c = window.confirm(
      "This activity will be removed from package, Are you sure you want to continue?"
    );
    if (c) {
      dispatch({
        type: reducerCases.SET_ECONOMY_PACKAGE,
        economyPackage: {
          ...economyPackage,
          price: economyPackage.price - 1500,
          dayOne: {
            ...economyPackage.dayOne,
            ujjainDarshan: false,
          },
        },
      });
    }
  };
  return (
    <>
      <div className="mb-10 min-h-[100vh]">
        <div id="booking-time" className="py-6 px-5 bg-slate-50 shadow-sm">
          <div className="flex md:flex-row md:gap-x-5 flex-col gap-y-3">
            <div className="md:ml-16 bg-white">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  onChange={handleDateChange}
                  minDate={today}
                  value={date}
                  sx={{ color: "grey", boxShadow: "10px" }}
                  disablePast
                  className="rounded-lg"
                  label="TRAVELLING ON"
                />
              </LocalizationProvider>
            </div>
            <div className="bg-white">
              <RoomsAndGuests handleApply={handleApply}/>
            </div>
          </div>
        </div>
        <p className="leading-[20px] text-red-500 text-center md:mx-20 mx-5 py-2">
          **Note - If arrival of travellers in Ujjain is at night/day before the
          day of the package is booked, then they can contact our team for their
          stay.**
          We recommend travellers to plan their arrival in Ujjain in the morning
          before 11:30 AM on the day of booking of the package.
        </p>
        {highlightMessage && (
            <div>

          <p className="leading-[20px] text-yellow-600 bg-yellow-100 border-l-4 border-yellow-500 text-center md:mx-20 mx-5 py-2 my-4 font-semibold">
            To get the best deals on this package for 5 or more people, specify the requirements (people, date, time,etc) to text message on 
            <a
              href="https://wa.me/message/H5LRCGI36GPIG1"
              className="text-green-600 underline mx-1 inline-flex items-center"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaWhatsapp className="mr-1" /> WhatsApp
            </a>
            number or call
            <span
            
              className="text-blue-600 underline mx-1"
            >
              +919201968470
              </span>
          
            so that we can give the discounted prices with excellent services.
          </p>
          </div>
        )}
        <div id="gallery" className="md:mx-20 mx-5">
          <h1 className="text-3xl font-bold mt-5 mb-1 ml-2">Premium Mahakaleshwar Package</h1>
          <div className="flex flex-row gap-x-2 ml-3">
            <DurationTag width={86} data={"Customizable"} />
            <DurationTag width={46} data={"1N/2D"} />
          </div>
        </div>

        
        <div>
          <div className="flex flex-row md:mx-20 mx-5 mt-5 mb-2 gap-x-1 pl-2">
            <button className="rounded-full border border-gray-400 hover:border-none focus:bg-orange-400 focus:border-none focus:text-white py-1.5 px-5 text-black transition-all hover:bg-orange-400 hover:text-white text-center text-sm ">
              Iternary
            </button>
            <button className="rounded-full border border-gray-400 hover:border-none focus:bg-orange-400 focus:border-none focus:text-white py-1.5 px-5 text-black transition-all hover:bg-orange-400 hover:text-white text-center text-sm">
              Summary
            </button>
          </div>
          <div className="py-3 md:mx-20 mx-10 px-3">
            <PickupRadio pack={"economyPackage"} price={2500}/>
          </div>

          <div className="shadow-xl border rounded-lg md:mx-20 mx-5 px-10">
            <Accordion type="multiple" collapsible className="w-full">
              <AccordionItem value="item-1">
                <AccordionTrigger>Day 1</AccordionTrigger>
                <AccordionContent>
                  <h3 className="text-2xl font-semibold my-1">Ujjain</h3>
                  <ul className="border rounded-lg py-3">
                    <li className="px-2 py-3">

                    <div className="">
                      <Dropdown incprice={1300} day={"dayOne"} packageName={packages.ECONOMY_PACKAGE} />
                      <ChooseVehicle
                        price={1300}
                        data={
                          " Transfer to hotel | Mahakaleshwar Darshan | Ujjain darsharn & sightseeing"
                        }
                        vehicle={economyPackage.dayOne.vehicle}
                        />
                    </div>
                        </li>
                    <li className="px-2">
                      <div className="ml-2">
                        <h1 className="text-lg font-semibold mt-2 inline-block">
                          <span className="header_span mr-1" />
                          Hotel Check-In (2⭐ or 3⭐)
                        </h1>
                        <h3 className="text-gray-500 ml-3">
                          - Our recommended Hotel near temple and with peaceful environment
                        </h3>
                        <div className="md:ml-10">
                          <Gallery images={[prop2, prop3]} about={"Property Photos"} />
                        </div>

                      </div>
                    </li>
                    <li className="px-2 py-3">
                      <div className="ml-2">
                        <h1 className="text-lg font-semibold mt-2 inline-block">
                          <span className="header_span mr-1" />
                          Mahakaleshwar VIP Darshan
                        </h1>
                        <h3 className="text-gray-500 ml-3">
                          - Private transefer from hotel to Mahakaleshwar
                          mandir.
                        </h3>
                      </div>
                    </li>
                    {economyPackage.dayOne.ujjainDarshan && (
                      <li className=" px-2 py-3">
                        <div className="ml-2">
                          <h1 className="text-lg font-semibold mt-2">
                            <span className="header_span mr-1" />
                            Ujjain Darshan (5 temples)
                            <span>
                              <button
                                onClick={handleRemoveUjjainDarshan}
                                className="text-[16px] font-normal text-red-500 ml-5 border-none hover:underline"
                              >
                                REMOVE
                              </button>
                            </span>
                          </h1>
                          <h3 className="text-gray-500 ml-3">
                            - Visit 5 Major temples, including (Kaal Bhairava,
                              Maa Harsiddhi Temple, Mangalnath Temple, Sandinpani Aashram, Bharthari Gufa and other).
                          </h3>
                        </div>
                      </li>
                    )}
                  </ul>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="item-3">
                <AccordionTrigger>Day 2</AccordionTrigger>
                <AccordionContent>
                  <h1 className="text-2xl font-semibold">
                    <span className="header_span mr-1" />
                    Check-out and Drop
                  </h1>
                  <h3 className="text-gray-500 ml-3">- Beakfast at hotel</h3>
                  <h3 className="text-gray-500 ml-3">
                    - Check-out from hotel and drop to your departure place.
                  </h3>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
        <div id="summary"></div>
      </div>
      <div className="z-10 bg-blue-600 sticky bottom-0 py-3 px-5 flex md:flex-row gap-y-2 flex-col">
        <div>

        <h3 className=" text-white font-bold text-2xl">
          Package Price : ₹{economyPackage.price}
        </h3>
        <p className="text-xs text-white">Excluding applicable tax</p>
        </div>
        <button onClick={handlePayment} className="ml-3 lg:ml-[70%] rounded-full border bg-white py-1.5 px-5 text-sky-600 transition-all hover:text-sky-700 hover:shadow-2xl text-center">
          Proceed to Payment
        </button>
      </div>
    </>
  );
};

export default EconomyPackage;
