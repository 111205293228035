import { createContext, useContext, useReducer } from "react";


export const AppContext = createContext();

export const StateProvider = ({initialState, reducer, children})=>(
    <AppContext.Provider value={useReducer(reducer, initialState)}>
        {children}
    </AppContext.Provider>
);

export const useStateProvider = ()=>useContext(AppContext);