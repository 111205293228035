import { useLocalStorage } from "CustomHooks/LocalStorage";
import { getPoojaMap } from "Poojas";
import { useStateProvider } from "context/StateContext";
import { reducerCases } from "context/constants";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const DetailModal = () => {
  const [setLocalStorage] = useLocalStorage("currentPooja")
  const [poojaMap, setpoojaMap] = useState(getPoojaMap())
  const [{modal}, dispatch] = useStateProvider();
  const {bool,src, title, desc} = modal
  const navigate = useNavigate()
  const handleModalClick = () => {
    dispatch({
      type: reducerCases.SET_MODAL,
        bool:false,
        ...modal
      ,
    });
  };
  const handleBookPooja = (title)=>{
    const {ind} = modal;
    let pooja = poojaMap.get(ind)
    dispatch({
      type:reducerCases.CURRENT_POOJA,
      currentPooja:pooja
    })
    localStorage.setItem("currentPooja",JSON.stringify(pooja))
    title = title.replaceAll(' ',"-")
    navigate(`/pooja/${title}`)
  }
  return (
    bool && (
      <div className="modal">
        <div onClick={handleModalClick} className="overlay"></div>
        <div className="modal-content transition-transform">
          <div>
            <div className=" ">
              <img className="shadow-sm rounded-md object-contain w-[100%]" src={src} alt="no-image" />
            </div>
            <div className="px-2">
              <h1 className="text-xl font-semibold pt-3 pb-1">{title}</h1>
              <hr className="border border-gray-300 border-b-0 mb-2"/>
              <h5 className="pb-2 text-gray-700 leading-5">{desc}</h5>
            </div>
            <div className="flex py-3 px-2">
              <button onClick={()=>handleBookPooja(title)} className="book_btn">Book Pooja</button>
              <button onClick={handleModalClick} className="close_btn mx-3">Close</button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default DetailModal;
