import React, { useEffect } from "react";
import premium from "assets/images/premium.png";
import pimg from "assets/images/Mahakal-Lok-Corridor-inauguration2.webp";
import { useStateProvider } from "context/StateContext";
import { reducerCases } from "context/constants";
import DurationTag from "components/Tags/DurationTag";
import { IoMdCheckmark } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import { trackButtonClicks } from "Analytics/GoogleAnalytics";
const BrowsePackages = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [{}, dispatch] = useStateProvider();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const classes = {
    list_class: "ml-5 md:indent-[-7px] text-sm font-normal text-gray-500",
  };
  const handleCardClick = (s) => {
    dispatch({
      type: reducerCases.SET_PREV_PAGE,
      prevPage: location.pathname,
    });
    navigate(`/packages/${s}`);
  };
  return (
    <div className="md:px-6 sm:px-15 pb-10">
      <h3 className="text-4xl text-center font-bold mt-5 mb-4 py-6">
        Our Packages
      </h3>
      <div className="flex flex-col lg:flex-row gap-20 items-center justify-center mx-5">
        <div
          onClick={() => {trackButtonClicks("Premium Package_Card_BrowsePackagePage","custom_event", "To Premium Package"); handleCardClick("premium-package")}}
          className="cursor-pointer shadow-2xl max-w-sm hover:-translate-y-2 transition-transform bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700"
        >
          <img height={100} className="rounded-lg" src={premium} alt="" />
          <div className="p-5 ">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              Premium Jyotirlinga Package
            </h5>
            <div className="">
              <div className="flex flex-row gap-x-3">
                <DurationTag width={45} data={"2N/3D"} />
                <DurationTag
                  color={"red"}
                  width={165}
                  data={"Jyotirlingam Tirath Special"}
                />
              </div>
              <div className="grid grid-cols-2">
                <ul className="list-disc pb-2">
                  <li className={classes.list_class}>2 Adults</li>
                  <li className={classes.list_class}>Origin pickup/drop</li>
                  <li className={classes.list_class}>Extra Bed</li>
                </ul>
                <ul className="list-disc">
                  <li className={classes.list_class}>2 or 3 Star Hotels</li>
                  <li className={classes.list_class}>Fully customizable</li>
                </ul>
              </div>
              <div className="pb-3">
                <ul className="">
                  <li className=" text-sm font-normal text-green-500">
                    <IoMdCheckmark className="inline-block mr-1 text-lg" />
                    Mahakaleshwar special Darshan
                  </li>
                  <li className=" text-sm font-normal text-green-500">
                    <IoMdCheckmark className="inline-block mr-1 text-lg" />
                    Omkareshwar Visit
                  </li>
                  <li className=" text-sm font-normal text-green-500">
                    <IoMdCheckmark className="inline-block mr-1 text-lg" />
                    Ujjain darshan and sight seeing
                  </li>
                </ul>
              </div>
            </div>
            <hr className="border mb-2" />
            <p className="mb-3 leading-5 text-gray-500 dark:text-gray-400">
              Buy this package and get best deals in hotels, food and sight
              seeing in Ujjain.
            </p>
            <div className="border rounded-md bg-slate-200 my-2 px-3 py-1">
              <p className=" text-lg font-semibold py-2">Rs. 13,200/- </p>
            </div>
          </div>
        </div>

        {/* Economy Package */}
        <div
          onClick={() =>{trackButtonClicks("Economy_Package_Card_BrowsePackagePage","custom_event", "To Economy Package"); handleCardClick("economy-package")}}
          className="cursor-pointer shadow-2xl max-w-sm hover:-translate-y-2 transition-transform bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700"
        >
          <img className="rounded-t-lg" src={pimg} alt="" />
          <div className="p-5 ">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              Premium Mahakaleshwar Package
            </h5>
            <div className="">
              <DurationTag width={45} data={"1N/2D"} />
              <div className="grid grid-cols-2">
                <ul className="list-disc pb-2">
                  <li className={classes.list_class}>2 Adults</li>
                  <li className={classes.list_class}>Origin pickup/drop</li>
                  <li className={classes.list_class}>Extra Bed</li>
                </ul>
                <ul className="list-disc">
                  <li className={classes.list_class}>2 or 3 Star Hotels</li>
                  <li className={classes.list_class}>Fully customizable</li>
                </ul>
              </div>
              <div className="pb-3">
                <ul className="">
                  <li className=" text-sm font-normal text-green-500">
                    <IoMdCheckmark className="inline-block mr-1 text-lg" />
                    Mahakaleshwar special Darshan
                  </li>
                  <li className=" text-sm font-normal text-green-500">
                    <IoMdCheckmark className="inline-block mr-1 text-lg" />
                    Ujjain darshan and sight seeing
                  </li>
                </ul>
              </div>
            </div>
            <hr className="border mb-2" />
            <p className="mb-3 leading-5 text-gray-500 dark:text-gray-400">
              Buy this package if you are on a short trip to Ujjain and want the
              most of it. We offer the best deal on Hotels and sightseeing.
            </p>
            <div className="border rounded-md bg-slate-200 my-2 px-3 py-1">
              <p className=" text-lg font-semibold py-2">Rs. 6,500/- </p>
            </div>
          </div>
        </div>

        {/* Standard Package */}
        <div className="relative">
          <div className=" shadow-2xl max-w-sm bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700">
            <div className="absolute z-[1] top-[50%] bg-white rounded-sm w-full">
              <h2 className="text-center font-bold text-2xl">Coming soon</h2>
            </div>
            <a href="#">
              <img className="rounded-t-lg" src={pimg} alt="" />
            </a>
            <div className="p-5">
              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                Standard package
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrowsePackages;
