import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useStateProvider } from 'context/StateContext';
import { reducerCases } from 'context/constants';
export default function PickupRadio({pack, price}) {
    const [value, setvalue] = React.useState(false);
    const [{}, dispatch] = useStateProvider();
    const handleChange = (e)=>{
        setvalue(e.target.value)
        if(e.target.value==="false"){
            dispatch({
                type:reducerCases.SET_PACKAGE_PRICE,
                setPrice:{
                    packageName:pack,
                    op:"dec",
                    price
                }
            });
            dispatch({
                type:reducerCases.SET_PICKUP,
                pickup:{
                    packageName:pack,
                    airportpick:false
                }
            })
        }else{
            dispatch({
                type:reducerCases.SET_PICKUP,
                pickup:{
                    packageName:pack,
                    airportpick:true
                }
            })
            dispatch({
                type:reducerCases.SET_PACKAGE_PRICE,
                setPrice:{
                    packageName:pack,
                    op:"inc",
                    price
                }
            });
        }
    }
  return (
    <FormControl size='small'>
      <FormLabel id="demo-radio-buttons-group-label">Pickup Location</FormLabel>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel value={false} control={<Radio />} label="Ujjain (anywhere in Ujjain)" />
        <FormControlLabel value={true} control={<Radio />} label="Indore Airport" />
      </RadioGroup>
    </FormControl>
  );
}
