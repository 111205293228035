import React from "react";
import namaste from "assets/images/namaste.png";
import avadhoot from 'assets/icons/avadhootLogo.png'
const AboutUs = () => {
  return (
    <div className="mt-10 mb-10 pb-10">
      <div className="mx-10 px-5">
        <div className="text-center my-10">
          <img width={200} src={avadhoot} className="inline-block" alt="" />
        </div>
        <h1 className="text-orange-800 text-5xl font-bold my-2 text-center">
          About Avadhoot Journey Private Limited
        </h1>
        <div className="flex justify-center mt-[50px]">
          <img width={100} src={namaste} alt="" />
        </div>
        <h3 className="text-red-800 text-2xl font-semibold text-center ">
          Jai Shree Mahakal
        </h3>
        <div className="mt-10">
          <h2 className="text-3xl font-bold text-center mt-5 mb-2 underline">
            Our Story
          </h2>
          <h3 className="text-xl">
            We are locals, born and bought up in Ujjain. And throughout the time
            we have seen people from all over the world visit Ujjain because of
            it's major significance in Hindu religion, It is the city of one of
            the 12 Jyotirlinga of lord Shiva, Mahakaleshwara, and also have it's
            name in World Heritage. And we observed that travelers often face
            challenges when booking hotels, cabs, and poojas. Many also
            encounter difficulties with temple darshans and struggle with
            planning their journeys. To address these issues, we have created an
            all-in-one online platform that allows visitors to Ujjain to
            seamlessly plan their trips with just a few clicks.
          </h3>
          <h2 className="text-3xl font-bold text-center mt-10 mb-2 underline">
            What we offer
          </h2>
          <h3 className="text-xl ">
            Our slogan, "Atithi Devo Bhavaha," embodies our commitment to
            treating each traveler with the utmost respect. At Avadhoot Journey,
            we offer highly affordable and customizable packages that cater to
            every need. From hotels, cabs, and Ujjain darshan to Jyotirlinga
            Tirath, sightseeing, meals, and arranging poojas, we provide
            everything a traveler might require for a memorable visit to Ujjain.
            And since we are local from Ujjain we designed these services with
            very reasonable prices, Since our goal is to make sure no one gets
            troubled or being looted while visiting the holy city Ujjain.
          </h3>
        </div>
        {/* <div id="founders">
            <h1 className="text-3xl font-bold text-center my-10 underline">Founding Members</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 justify-center">
                <div>
                    <div>
                        <img src="" alt="no-img" />
                    </div>
                    <h3 className="">Prakhar Singh Pawar</h3>
                    <h4>Founder & Head of operations</h4>
                </div>
                <div>
                    <div>
                        <img src="" alt="no-img" />
                    </div>
                    <h3 className="">Tanishk Sharma</h3>
                    <h4>Co-Founder & Lead Developer</h4>
                </div>
                <div>
                    <div>
                        <img src="" alt="no-img" />
                    </div>
                    <h3 className="">Suraj Thakur</h3>
                    <h4>Co-Founder</h4>
                </div>
                <div>
                    <div>
                        <img src="" alt="no-img" />
                    </div>
                    <h3 className="">Ankit Methaniya</h3>
                    <h4>Co-Founder & Lead Developer</h4>
                </div>
            </div>

        </div> */}
      </div>
    </div>
  );
};

export default AboutUs;
