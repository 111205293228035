import dayjs from "dayjs";
export const getFullDate = () => {
  const date = new Date();
  let d = "";
  const day = date.getDate() + 1;
  const month = date.getMonth();
  const year = date.getFullYear();
  
  return dayjs(`${year}-${month+1}-${day}`)
};

export const getFullDatePackage = () => {
  const date = new Date();
  let d = "";
  const day = date.getDate() + 3;
  const month = date.getMonth();
  const year = date.getFullYear();
  
  return dayjs(`${year}-${month+1}-${day}`)
};
export const formatPhoneNumber = (s)=>{
  // let formattedNum = `+${s.replace(/\D/g,"")}`
  let formattedNum =
  "+" +
  s.slice(0, 2) +
  "" +
  s.slice(2, 6) +
  "" +
  s.slice(6, 9) +
  "" +
  s.slice(9, 12);
  return formattedNum
}