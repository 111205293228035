import mahakal1 from "./mahakal1.jpg"
import kaal1 from "./KalBhairav1.jpg"
import harsiddhi1 from "./harsiddhi1.jpg"
import mangalNath1 from "./mangalnath1.jpg"

export const TempleImages = {
    0:[mahakal1],
    1:[kaal1],
    2:[harsiddhi1],
    3:[mangalNath1]
}