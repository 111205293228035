import * as React from "react";
import TempleCard from "components/TempleCard";
import { Temples } from "Temples";

const BrowseTemples = () => {
  return (
    <div className="mt-10 mx-5 px-5 mb-5 pb-3">
        <div className="my-5 py-3 text-center mb-5">
            <h1 className="text-3xl text-orange-500 font-bold">
                Temples in Ujjain
            </h1>
        </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-5 gap-y-10 justify-items-center">
        {Temples.map((temple) => {
          return (
            <TempleCard
                id={temple.id}
              key={temple.id}
              name={temple.name}
              long_desc={temple.long_desc}
              darshan_timing={temple.darshan_timings}
              aarti_timings={temple.aarti_timings}
              poojas={temple.poojas}
            />
          );
        })}
      </div>
    </div>
  );
};

export default BrowseTemples;
