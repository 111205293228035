import React from "react";
import ContactUs from "./ContactUs";
import PackageCard from "./PackageCard";
import priestLogo from "assets/icons/priest.png";
import customLogo from "assets/icons/gift.png";
import templeLogo from "assets/icons/temple.png";
import poojaLogo from "assets/icons/pooja.png";
import { GrAndroid } from "react-icons/gr";
import { FaApple } from "react-icons/fa6";
import Footer from "./Footer";
import Carousel from "./Carousel";
import Goals from "./Goals";
import { trackButtonClicks } from "Analytics/GoogleAnalytics";
import avatar from "assets/images/default_avatar.jpg"
import { useNavigate } from "react-router-dom";
import ReviewCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1324 },
    items: 3,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 1324, min: 1000 },
    items: 3,
    slidesToSlide: 1.5,
  },
  tablet: {
    breakpoint: { max: 1000, min: 664 },
    items: 2,
    slidesToSlide: 1.5,
  },
  mobile: {
    breakpoint: { max: 664, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const Empty = () => {
  const handleActivityClick = () => {};
  const navigate = useNavigate();

  const dummyReviews = [
    {
      id: 0,
      name: "Amit Verma, Kolkata",
      img: avatar,
      review:
        "I was on a short trip to Ujjain and booked their Mahakal package. The accommodation which seem to affiliated with MP tourism  was comfortable. The VIP Darshan of Mahakal was worth it due to weekend crowds. I’m really happy with the service and will book with them again!",
      rating: 5,
    },
    {
      id: 1,
      name: "Ravi Sharma, Mumbai",
      img: avatar,
      review:
        "I booked the Kal Sarp Dosh pooja, and everything went smoothly. The pandits followed all the rituals, and the rates were reasonable. The experience was fulfilling, and I felt at peace. I’ll definitely use this site for future trips.",
      rating: 4.3,
    },
    {
      id: 2,
      name: "Priya Singh, Jaipur",
      img: avatar,
      review:
        "We were 9 people, and they customized the tour for us. Everything was great, from pickup to the hotel near the temple. The VIP Darshan saved time, and the Omkareshwar visit was well organized. The rates were fair with no hidden costs. I highly recommend them!",
      rating: 4,
    },
  ];
  return (
    <div className="overflow-x-hidden">
      <Carousel />
      {/* Activities Section */}
      <section className="w-full  bg-slate-50 lg:h-[80vh] flex flex-col p-5">
        <div className="p-4 mt-5">
          <h1 className="text-center font-bold text-4xl">Our Services</h1>
        </div>
        <div className="p-8 w-full grid gap-y-10 grid-cols-1 md:grid-cols-2 lg:grid-cols-4 justify-items-center">
          <PackageCard
            onClick={() =>
              trackButtonClicks(
                "Poojas_Card_HomePage",
                "custom_event",
                "To browse Poojas"
              )
            }
            src={poojaLogo}
            height={150}
            width={150}
            content={"Browse Poojas and Havans"}
            to={"/browse-poojas"}
          />
          <PackageCard
            src={templeLogo}
            height={150}
            width={150}
            content={"Browse temples in Ujjain"}
            to={"/browse-temples"}
          />
          <PackageCard
            comingSoon={true}
            src={priestLogo}
            height={150}
            width={150}
            content={"Talk to our trusted Pandit Ji's"}
            // to={"/hotels"}
          />
          <PackageCard
            onClick={() =>
              trackButtonClicks(
                "Packages_Card_HomePage",
                "custom_event",
                "To browse Packages"
              )
            }
            src={customLogo}
            height={150}
            width={150}
            content={"Plan your trip with our packages."}
            to={"/browse-packages"}
          />
        </div>
      </section>
      {/* <hr className="border border-gray-300 mx-10"/> */}
      {/* Goals Section */}
      <section className="w-full flex flex-col p-5 pb-20">
        <div>
          <h1 className="py-5 pt-10 font-bold text-4xl text-center">
            Our Goals
          </h1>
          <Goals />
        </div>
      </section>

      {/* App download section */}
      <section className=" mb-10 p-6 flex justify-center items-center">
        <div className="h-auto py-4 flex flex-col md:flex-row  px-5 justify-evenly w-full items-start max-w-[1200px]">
          <div className="border-solid w-full md:h-full md:w-[60%]  overflow-hidden">
            <div className="w-full h-full">
              <div className="p-2 w-full">
                <h1 className="text-2xl md:text-4xl font-semibold text-black pb-4 max-w-[400px]">
                  Download the mobile App for better experience
                </h1>
                <div className="flex flex-wrap  justify-around items-center md:justify-start md:items-start md:flex-col">
                  <button
                    onClick={() => navigate("/comingSoon")}
                    className="download_btn flex items-center gap-2"
                    type="button"
                  >
                    <GrAndroid className="text-2xl" />
                    Android Download
                  </button>
                  <button
                    onClick={() => navigate("/comingSoon")}
                    className="download_btn flex items-center gap-2"
                    type="button"
                  >
                    <FaApple className="text-2xl" />
                    IOS Download
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* Mobile images */}
          <div className="mobiles flex flex-col md:flex-row justify-around items-center w-full h-[100%] md:w-[70%] gap-2">
            <div className="mobileImgShow flex justify-around items-center w-[60%] md:w-[100%] h-[60%]   p-2 max-w-[900px] max-h-[400px] ">
              <img
                src="/mobileView.png"
                alt="mobile app image"
                className="h-[100%] md:h-[90%] w-[45%] md:w-[30%] md:max-w-[400px] md:max-h-[400px] object-cover"
              />
              <img
                src="/mobileView2.png"
                alt="mobile app image"
                className="h-[80%]  md:h-[10%] w-[45%] md:w-[29%] md:max-w-[400px] md:max-h-[400px] object-cover"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Costumer reviews */}
      <div className="costumerReviews font-[Inter] py-6 min-h-[40vh] flex justify-center flex-col myCarouselBtn">
        <h3 className="font-bold text-3xl md: ml-4">Customer Reviews</h3>

        <ReviewCarousel
          transitionDuration={700}
          infinite={true}
          autoPlaySpeed={4000}
          keyBoardControl={true}
          autoPlay
          className=" h-[100%] py-8"
          responsive={responsive}
        >
          {dummyReviews.map((review) => (
            <div
              key={review.id}
              className="flex-shrink-0 flex flex-col ml-3 mr-3 max-w-md p-4 justify-between  shadow-xl h-full  rounded-lg mx-2"
            >
              <p className="text-sm mb-2">{review.review}</p>
              <div className="flex gap-2 items-center  justify-start ">
                <img
                  src={review.img}
                  alt={review.name}
                  className="rounded-full h-[2.5rem] w-[2.5rem] gap-2 object-cover"
                />
                <div className="text-[14px]  items-center font-semibold mt-2">
                  <span>{review.name}</span>
                  <div className="flex items-center">
                    {Array.from({ length: review.rating }).map((_, index) => (
                      <svg
                        key={index}
                        className="w-4 h-4 text-yellow-500 fill-current"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path d="M12 2l2.39 4.81 5.33.77-3.87 3.77.91 5.3-4.77-2.51-4.77 2.51.91-5.3L4.28 8.58 9.61 7.81 12 2z" />
                      </svg>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </ReviewCarousel>
      </div>
      
      {/* Contact us section */}
      <ContactUs />
      <Footer />
    </div>
    // another section for about content and footer
  );
};
export default Empty;
